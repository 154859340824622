/* eslint-disable no-useless-computed-key */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Tab, Nav, Button, Modal } from "react-bootstrap";
import { FiSliders, FiShoppingBag, FiLogOut, FiBook } from "react-icons/fi";
import {
  getUserProfie,
  orderHistory,
  postNewAdress,
  editAdress,
  deleteAdress,
} from "../service/api";
import { useNavigate, Link } from "react-router-dom";
import actions from "../redux/user/actions";
import productActions from "../redux/products/actions";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { useSnackbar } from "notistack";
import countries from "countries-list";
import { YOUR_GOOGLE_MAPS_API_KEY } from "../config";
import Autocomplete from "react-google-autocomplete";
import moment from "moment";
import { ToastContainer, toast } from "react-toast";
import SpinnerComponent from "../components/Spinner";

function MyAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = [];
  Object.entries(countries?.countries).map((key) => {
    state.push({ value: key[0], label: key[1].name });
  });

  const [userData, setUserData] = useState();
  useEffect(() => {
    getUserProfie().then((res) => {
      setUserData(res?.data);
      console.log(res?.data);
    });
  }, []);
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(1);
  const [order, setOrder] = useState([]);
  console.log("%c Line:47 🍪 order", "color:#b03734", order);
  const [totalResults, setTotalResults] = useState();
  useEffect(() => {
    const obj = {};
    obj.page = page;
    obj.sizePerPage = 5;

    orderHistory(obj).then((res) => {
      if (res?.status) {
        console.log("order", res?.data);
        setOrder(res?.data?.docs);
        setTotalResults(res?.data?.totalDocs);
      }
    });
  }, [page]);

  const logoutFun = () => {
    enqueueSnackbar("You have logged out of your account successfully.", {
      variant: "success",
    });
    navigate("/register");
    // localStorage.removeItem("authentication")
    // localStorage.removeItem("token")
    localStorage.clear();
    dispatch(actions.setUser(null));
    dispatch(productActions.logout());
    dispatch(actions.setToken(null));
    dispatch(actions.setLoggedIn(false));
  };

  const [addressModal, setAddressModal] = useState(false);

  const formatDate = (data) => {
    let day = new Date(data).getDate();
    let month = new Date(data).getMonth() + 1;
    let year = new Date(data).getFullYear();
    return `${month}/${day}/${year}`;
  };

  const left = () => {
    if (page === 1) {
      return;
    }
    setPage(page - 1);
  };

  const right = () => {
    if (page * 5 >= totalResults) {
      return;
    }
    setPage(page + 1);
  };

  const viewOrder = (data) => {
    console.log(data);
    dispatch(productActions.setOrderDetails(data));
    navigate("/order-details");
  };

  const [modalState, setModalState] = useState();

  const [addressId, setAddressId] = useState();
  const [input, setInput] = useState({
    // fullName: "",
    // companyName: "",
    address: "",
    firstName:"",
    lastName:"",
    addressLine1: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });

  const [inputError, setInputError] = useState({
    address: false,
    addressLine1: false,
    city: false,
    state: false,
    zipCode: false,
    country: false,
    lastName:false,
    firstName:false
  });

  const [selectAdressId, setselectAdressId] = useState(null);
  const [loading, setLoading] = useState(false);

  const addAdress = (e) => {
    e.preventDefault();

    console.log("input", input);
    if (!input?.address) {
      setInputError({ ...inputError, address: true });
      return;
    }
    // if (!input?.addressLine1) {
    //   setInputError({ ...inputError, addressLine1: true })
    //   return
    // }
    if (!input?.city) {
      setInputError({ ...inputError, city: true });
      return;
    }
    if (!input?.state) {
      setInputError({ ...inputError, state: true });
      return;
    }
    if (!input?.firstName) {
      setInputError({ ...inputError, firstName: true });
      return;
    }
    if (!input?.lastName) {
      setInputError({ ...inputError, lastName: true });
      return;
    }
    if (!input?.zipCode) {
      setInputError({ ...inputError, zipCode: true });
      return;
    }

    if (!input?.country) {
      setInputError({ ...inputError, country: true });
      return;
    }
    if (input?.addressLine1 === "") {
      delete input?.addressLine1;
    }
    console.log("input", input);
    setLoading(true);
    setAddressModal(false);

    if (modalState === "Add") {
      postNewAdress(input)
        .then((res) => {
          console.log(res);
          if (res?.status)
            enqueueSnackbar(res?.message, { variant: "success" });

          getUserProfie().then((res) => {
            setUserData(res?.data);
            console.log(res?.data);
            setLoading(false);
          });
          setInput({
            ...input,
            address: "",
            addressLine1: "",
            city: "",
            state: "",
            zipCode: " ",
            country: "",
          });
        })
        .catch((e) => {
          enqueueSnackbar(e?.message, { variant: "error" });
        });
      // .finally(res => {
      //   setLoading(false)
      // })
    } else if (modalState === "Edit") {
      editAdress(addressId, input)
        .then((res) => {
          console.log(res);
          if (res?.status)
            enqueueSnackbar(res?.message, { variant: "success" });
          getUserProfie().then((res) => {
            setUserData(res?.data);
            console.log(res?.data);
            setLoading(false);
          });
          setInput({
            ...input,
            address: "",
            addressLine1: "",
            city: "",
            state: "",
            zipCode: " ",
            country: "",
          });
        })
        .catch((e) => {
          enqueueSnackbar(e?.message, { variant: "error" });
        });
      // .finally(res => {
      //   setLoading(false)
      // })
    }
  };

  const deleteAdressFun = (id) => {
    console.log(id);
    setLoading(true);
    deleteAdress(id)
      .then((res) => {
        console.log(res);
        if (res?.status) enqueueSnackbar(res?.message, { variant: "success" });
        getUserProfie().then((res) => {
          setUserData(res?.data);
          setLoading(false);
        });
      })
      .catch((e) => {
        enqueueSnackbar(e?.message, { variant: "error" });
      });
  };

  let streetNumber = "";
  let streetName = "";

  return (
    <div className="my-account">
      <section className="pb-50 pt-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <Tab.Container defaultActiveKey="dashboard">
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link className="account-tabs" eventKey="dashboard">
                          <FiSliders /> Dashboard
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link className="account-tabs" eventKey="orders">
                          <FiShoppingBag />
                          Orders
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className="account-tabs"
                          eventKey="saved-addresses"
                        >
                          <FiBook />
                          Saved Address
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className="account-tabs"
                          eventKey="logout"
                          style={{
                            borderBottom: "0px solid transparent",
                          }}
                          onClick={logoutFun}
                        >
                          <FiLogOut />
                          Logout
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="col-md-8 col-sm-12">
                    <Tab.Content>
                      <Tab.Pane eventKey="dashboard">
                        <div className="card">
                          <div className="card-header">
                            <h5 className="mb-0">
                              Hello {userData?.name} ({userData?.email})
                              <span
                                style={{
                                  float: "right",
                                }}
                              >
                                Reward :{" "}
                                {userData?.points ? userData?.points : 0}
                              </span>
                            </h5>
                          </div>
                          <div className="card-body">
                            <p>
                              From your account dashboard, you can easily check
                              and view your recent orders, manage your shipping
                              and billing address and edit your password and
                              account details.
                            </p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="orders">
                        <div className="card">
                          <div className="card-header">
                            <h5 className="mb-0">Your Orders</h5>
                          </div>
                          <div className="card-body">
                            <div className="table-responsive">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Order</th>
                                    <th>Date</th>
                                    <th>Product Name</th>
                                    <th>Payment Details</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {order?.map((data) => {
                                    return (
                                      <tr>
                                        <td>#{data?.orderId}</td>
                                        <td>
                                          {moment(data?.createdAt).format("L")}
                                        </td>
                                        <td>{data?.order[0]?.product?.name}</td>
                                        <td>
                                          <p className="mb-0">
                                            <b>Price:</b>
                                            {data?.amount?.toFixed(2)}
                                          </p>
                                        </td>
                                        <td>
                                          <div
                                            onClick={() => viewOrder(data)}
                                            style={{
                                              textDecoration: "none",
                                              color: "#005CB9",
                                              cursor: "pointer",
                                              fontWeight: "800",
                                              display: "block",
                                            }}
                                          >
                                            <span>View order</span>
                                          </div>
                                          {/* <span
                                            className="mt-1"
                                            style={{
                                              textDecoration: "none",
                                              color: "red",
                                              cursor: "pointer",
                                              fontWeight: "800",
                                              display: "block",
                                            }}
                                          >
                                            Track your order
                                          </span> */}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>

                              <div
                                className="d-flex mt-3"
                                style={{
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  className="pagination-btn prev"
                                  disabled={page === 1 ? true : false}
                                  onClick={left}
                                >
                                  Prev
                                </Button>
                                <Button
                                  className="pagination-btn next"
                                  disabled={
                                    page * 5 >= totalResults ? true : false
                                  }
                                  onClick={right}
                                >
                                  Next
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="saved-addresses">
                        <div className="card">
                          <div className="card-header">
                            <h5 className="mb-0">Your Saved Addresses</h5>
                          </div>
                          <div className="card-body">
                            {!loading ? (
                              <div className="saved-addresses">
                                <div className="row">
                                  {userData?.address?.map((data) => {
                                    return (
                                      <div className="col-xl-4 col-sm-12">
                                        <div
                                          className={`address-box  ${
                                            selectAdressId === data?._id
                                              ? "selected"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (selectAdressId === data?._id) {
                                              setselectAdressId(null);
                                              return;
                                            }
                                            setselectAdressId(data?._id);
                                          }}
                                        >
                                          <div className="address-details">
                                            <p>
                                              {data?.address &&
                                                data?.address?.split(",")[0]}
                                            </p>
                                            <p>{data?.addressLine1}</p>
                                            <div className="address">
                                              <p>{data?.city}</p>
                                              <p>
                                                {data?.state}, {data?.zipCode}
                                              </p>
                                              <p>{data?.country}</p>
                                            </div>
                                            <div className="actions">
                                              <Button
                                                className="edit-btn"
                                                onClick={() => {
                                                  setAddressModal(true);
                                                  setModalState("Edit");
                                                  setAddressId(data?._id);
                                                  setInput({
                                                    ...input,
                                                    address: data?.address,
                                                    addressLine1:
                                                      data?.addressLine1,
                                                    city: data?.city,
                                                    state: data?.state,
                                                    zipCode: data?.zipCode,
                                                    country: data?.country,
                                                  });
                                                }}
                                              >
                                                Edit
                                              </Button>
                                              <Button
                                                className="delete-btn"
                                                onClick={() => {
                                                  deleteAdressFun(data?._id);
                                                }}
                                              >
                                                Delete
                                              </Button>
                                            </div>
                                          </div>
                                          <div className="selected-icon">
                                            <img
                                              src={require("../assets/icons/check.png")}
                                              width="25"
                                              alt="Address Selected"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="col-xl-4 col-sm-12">
                                    <div
                                      className="address-box"
                                      onClick={() => {
                                        setAddressModal(true);
                                        setModalState("Add");
                                      }}
                                    >
                                      Add new address
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <SpinnerComponent />
                            )}
                          </div>
                        </div>

                        <Modal
                          size="lg"
                          show={addressModal}
                          onHide={() => setAddressModal(!addressModal)}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title className="address-modal-title">
                              {modalState} Address
                            </Modal.Title>
                          </Modal.Header>

                          <Modal.Body className="address-modal-body">
                            <form>
                              <div className="row">
                                <div className="col-xl-12 col-sm-12">
                                  <div className="form-group mb-2">
                                    <label htmlFor="address">Address</label>
                                    <Autocomplete
                                      className="form-control"
                                      apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                                      options={{
                                        types: ["address"],
                                      }}
                                      // defaultValue={input?.address}
                                      onChange={(e) => {
                                        setInput({
                                          ...input,
                                          address: e.target.value,
                                        });
                                        setInputError({
                                          ...inputError,
                                          address: false,
                                        });
                                      }}
                                      onPlaceSelected={(place) => {
                                        console.log(place);
                                        // input.address =
                                        //   place?.formatted_address;
                                        // let address = place?.formatted_address;
                                        // (place?.address_components?.filter(
                                        //   (data) =>
                                        //     data?.types?.includes(
                                        //       "street_number"
                                        //     )
                                        // )?.length > 0
                                        //   ? place?.address_components?.filter(
                                        //       (data) =>
                                        //         data?.types?.includes(
                                        //           "street_number"
                                        //         )
                                        //     )[0]?.long_name
                                        //   : "") +
                                        // " " +
                                        // (place?.address_components?.filter(
                                        //   (data) =>
                                        //     data?.types?.includes("route")
                                        // )?.length > 0
                                        //   ? place?.address_components?.filter(
                                        //       (data) =>
                                        //         data?.types?.includes("route")
                                        //     )[0]?.long_name
                                        //   : "");
                                        // console.log("address: ", address);
                                        // input.address = address;
                                        // console.log(
                                        //   "address",
                                        //   place?.formatted_address
                                        // );
                                        // setInput({
                                        //   ...input,
                                        //   address: address,
                                        // });
                                        // setInputError({
                                        //   ...inputError,
                                        //   address: false,
                                        // });
                                        let address = "";

                                        place?.address_components?.map(
                                          (data) => {
                                            if (
                                              data?.types?.includes(
                                                "street_number"
                                              )
                                            ) {
                                              address =
                                                address + data?.long_name + " ";
                                            }
                                            if (
                                              data?.types?.includes("route")
                                            ) {
                                              address =
                                                address + data?.long_name + " ";
                                            }
                                            input.address = address;
                                            setInput({
                                              ...input,
                                              address: address,
                                            });
                                            setInputError({
                                              ...inputError,
                                              address: false,
                                            });
                                            /* COUNTRY */
                                            if (
                                              data?.types?.includes("country")
                                            ) {
                                              input.country = data?.long_name;
                                              // setInput(input);
                                              console.log("input: ", input);
                                              setInputError({
                                                ...inputError,
                                                country: false,
                                              });
                                              console.log(
                                                "Country",
                                                data?.long_name
                                              );
                                            }

                                            /* STATE */
                                            if (
                                              data?.types?.includes(
                                                "administrative_area_level_1"
                                              )
                                            ) {
                                              setInput({
                                                ...input,
                                                state: data?.long_name,
                                              });
                                              setInputError({
                                                ...inputError,
                                                state: false,
                                              });
                                              input.state = data?.long_name;
                                              console.log(
                                                "State",
                                                data?.long_name
                                              );
                                            }

                                            /* CITY */
                                            if (
                                              data?.types?.includes("locality")
                                            ) {
                                              setInputError({
                                                ...inputError,
                                                city: false,
                                              });
                                              input.city = data?.long_name;
                                            }

                                            /* ZIPCODE */
                                            if (
                                              data?.types?.includes(
                                                "postal_code"
                                              )
                                            ) {
                                              setInput({
                                                ...input,
                                                zipCode: data?.long_name,
                                              });
                                              setInputError({
                                                ...inputError,
                                                zipCode: false,
                                              });
                                              input.zipCode = data?.long_name;
                                            }

                                            /* ADDRESS LINE 1 */
                                            /* if (
                                              data?.types?.includes(
                                                "administrative_area_level_3"
                                              ) ||
                                              data?.types?.includes(
                                                "administrative_area_level_2"
                                              )
                                            ) {
                                              setInputError({
                                                ...inputError,
                                                addressLine1: false,
                                              });
                                              input.addressLine1 =
                                                data?.long_name;
                                            } */
                                          }
                                        );
                                      }}
                                    />
                                  </div>
                                  <small
                                    className="validation-error"
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    {/* {inputError?.address
                                      ? "Address line 1 is required"
                                      : ""} */}
                                  </small>
                                </div>
                                <div className="col-xl-12 col-sm-12">
                                  <div className="form-group mb-2">
                                    <input
                                      type="text"
                                      id="address"
                                      name="address"
                                      value={input?.address}
                                      className="form-control"
                                      placeholder="Address Line 1"
                                      onChange={(e) => {
                                        setInputError({
                                          ...inputError,
                                          [e.target.name]: false,
                                        });
                                        setInput({
                                          ...input,
                                          [e.target.name]: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <small
                                    className="validation-error"
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    {inputError?.address
                                      ? "Address line 1 is required"
                                      : ""}
                                  </small>
                                </div>
                                <div className="col-xl-12 col-sm-12">
                                  <div className="form-group mb-2">
                                    <input
                                      type="text"
                                      id="addressLine1"
                                      name="addressLine1"
                                      value={input?.addressLine1}
                                      className="form-control"
                                      placeholder="Address Line 2"
                                      onChange={(e) => {
                                        setInputError({
                                          ...inputError,
                                          [e.target.name]: false,
                                        });
                                        setInput({
                                          ...input,
                                          [e.target.name]: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <small
                                    className="validation-error"
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    {inputError?.addressLine1
                                      ? "Address line 2 is required"
                                      : ""}
                                  </small>
                                </div>
                                <div className="col-xl-4 col-sm-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      id="firstName"
                                      name="firstName"
                                      value={input?.firstName}
                                      className="form-control"
                                      placeholder="firstName"
                                      onChange={(e) => {
                                        setInputError({
                                          ...inputError,
                                          [e.target.name]: false,
                                        });
                                        setInput({
                                          ...input,
                                          [e.target.name]: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <small
                                    className="validation-error"
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    {inputError?.firstName
                                      ? "firstName 1 is required"
                                      : ""}
                                  </small>
                                </div>
                                <div className="col-xl-4 col-sm-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      id="lastName"
                                      name="lastName"
                                      value={input?.lastName}
                                      className="form-control"
                                      placeholder="lastName"
                                      onChange={(e) => {
                                        setInputError({
                                          ...inputError,
                                          [e.target.name]: false,
                                        });
                                        setInput({
                                          ...input,
                                          [e.target.name]: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <small
                                    className="validation-error"
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    {inputError?.lastName
                                      ? "lastName 1 is required"
                                      : ""}
                                  </small>
                                </div>
                                <div className="col-xl-4 col-sm-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      id="city"
                                      name="city"
                                      value={input?.city}
                                      className="form-control"
                                      placeholder="City"
                                      onChange={(e) => {
                                        setInputError({
                                          ...inputError,
                                          [e.target.name]: false,
                                        });
                                        setInput({
                                          ...input,
                                          [e.target.name]: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <small
                                    className="validation-error"
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    {inputError?.city
                                      ? "city 1 is required"
                                      : ""}
                                  </small>
                                </div>
                                <div className="col-xl-4 col-sm-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      id="state"
                                      name="state"
                                      value={input?.state}
                                      className="form-control"
                                      placeholder="State "
                                      onChange={(e) => {
                                        setInputError({
                                          ...inputError,
                                          [e.target.name]: false,
                                        });
                                        setInput({
                                          ...input,
                                          [e.target.name]: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <small
                                    className="validation-error"
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    {inputError?.state
                                      ? "state is required"
                                      : ""}
                                  </small>
                                </div>
                                <div className="col-xl-4 col-sm-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      id="zipCode"
                                      name="zipCode"
                                      value={input?.zipCode}
                                      className="form-control"
                                      placeholder="Pin code"
                                      onChange={(e) => {
                                        setInputError({
                                          ...inputError,
                                          [e.target.name]: false,
                                        });
                                        setInput({
                                          ...input,
                                          [e.target.name]: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <small
                                    className="validation-error"
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    {inputError?.zipCode
                                      ? "zip Code is required"
                                      : ""}
                                  </small>
                                </div>
                                <div className="col-xl-6 col-sm-12">
                                  <div className="form-group">
                                    <label htmlFor="country">Country</label>
                                    {console.log(
                                      "input: ",
                                      input,
                                      state.filter(
                                        (obj) => obj?.label === input.country
                                      )[0]?.value
                                    )}
                                    <Select
                                      value={{
                                        label: input?.country,
                                        value: state.filter(
                                          (obj) => obj?.label === input.country
                                        )[0]?.value,
                                      }}
                                      className="state-selection"
                                      classNamePrefix="states"
                                      options={state}
                                      name="country"
                                      onChange={(e) => {
                                        setInputError({
                                          ...inputError,
                                          ["country"]: false,
                                        });
                                        setInput({
                                          ...input,
                                          country: e.label,
                                        });
                                      }}
                                    />
                                  </div>
                                  <small
                                    className="validation-error"
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    {inputError?.country
                                      ? "country 1 is required"
                                      : ""}
                                  </small>
                                </div>
                              </div>
                            </form>
                          </Modal.Body>

                          <Modal.Footer>
                            <Button
                              className="add-address-btn"
                              onClick={addAdress}
                            >
                              Save Address
                            </Button>{" "}
                            {/* IF USED FOR CREATING NEW ADDRESS, BUTTON TEXT WILL BE ADD ADDRESS */}
                          </Modal.Footer>
                        </Modal>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MyAccount;
