import React, { useEffect } from "react";
import { useState } from "react";
import ReactCardFlip from "react-card-flip";
import { IMG_URL } from "../config";
import { useSelector, useDispatch } from "react-redux";
import { postWishList } from "../service/api";
import actions from "../redux/products/actions";
import soldOutTag from "../assets/sold-out-tag.png";

function ProductBox(props) {
    const dispatch = useDispatch();
    const [flip, setFlip] = useState(false);
    const products = useSelector((state) => state.products);
    const user = useSelector((state) => state.user);
    let isShow = false;

    if (props?.quantity) {
        const findColor = props?.quantity.find(
            (element) => element?.color === props?.colorId,
        );
        findColor?.quantity < 3 ? (isShow = true) : (isShow = false);
    }

    const [wishlistActive, setwishlistActive] = useState(false);

    useEffect(() => {
        if (props?.wishList?._id) {
            console.log("props?.wishList?._id", props?.wishList?._id);
            setwishlistActive(true);
        }
    }, [props?.wishList]);

    const wishListFun = (e, id, colorId, wishList) => {
        e.stopPropagation();
        if (!user?.token) {
            console.log("open modal");
            dispatch(actions.setModal(true));
            return;
        }
        dispatch(actions.setWishlistTrigger("start"));
        console.log("wishList", wishList);
        console.log("wishlistActive", wishlistActive);
        if (wishlistActive) {
            setwishlistActive(false);
            const formData = new FormData();
            formData.append("product", id);
            formData.append("color", colorId);
            postWishList(formData).then((res) => {
                console.log("res", res);
                dispatch(actions.setWishlistTrigger("stop"));
            });
            dispatch(actions.setWishList(products.wishList - 1));
        } else {
            const formData = new FormData();
            formData.append("product", id);
            formData.append("color", colorId);
            postWishList(formData).then((res) => {
                console.log("res", res);
                dispatch(actions.setWishlistTrigger("stop"));
            });
            dispatch(actions.setWishList(products.wishList + 1));
            setwishlistActive(true);
        }
    };
    const size = props.subDescriptions
        ? Object.values(props.subDescriptions)
        : "";
    return (
        <div className="product-box" key={props.id}>
            <div
                onMouseEnter={() => setFlip(!flip)}
                onMouseLeave={() => setFlip(!flip)}
            >
                <ReactCardFlip isFlipped={flip} flipDirection="horizontal">
                    <div>
                        <img
                            src={`${IMG_URL}${props?.img}`}
                            alt={"Buy" + props.name}
                        />
                        {isShow ? (
                            <div class="topleft">
                                <img
                                    className="sold-out-image"
                                    src={soldOutTag}
                                    alt={"soldout"}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="onHover-product-box">
                        <div className="mb-3">
                            <p className="onHover-label">Composition</p>
                            <p className="onHover-content">
                                {props.composition?.map((data, i) => {
                                    if (props.composition?.length === i + 1) {
                                        return <>{data?.name}</>;
                                    } else return <>{data?.name + ", "}</>;
                                })}
                            </p>
                        </div>
                        <p className="onHover-label">Size</p>
                        <p className="onHover-content">{size[0]}</p>
                    </div>
                </ReactCardFlip>
            </div>
            <div className="product-name">
                <h5>{props.name}</h5>
                <p>
                    {props?.specialAmount ? (
                        props?.specialAmount > 0 ? (
                            <>
                                <span
                                    style={{ textDecoration: "line-through" }}
                                >
                                    {"₹" + props?.price}
                                </span>
                                <span
                                    style={{
                                        fontSize: "18px",
                                        color: "#FF0000",
                                    }}
                                >
                                    &nbsp; {"₹" + props?.specialAmount}
                                </span>
                            </>
                        ) : (
                            "₹" + props?.price
                        )
                    ) : (
                        "₹" + props?.price
                    )}
                    {isShow ? (
                        <span
                            className="sold-out-text"
                            style={{
                                float: "right",
                                fontWeight: "700",
                                color: "red",
                            }}
                        >
                            Sold Out
                        </span>
                    ) : (
                        ""
                    )}
                </p>
                {props?.specialAmount && props.specialAmount > 0 && (
                    <p>
                        <span style={{ color: "red" }}>
                            {(
                                ((props.price - props.specialAmount) /
                                    props.price) *
                                100
                            ).toFixed(0)}
                            % Off
                        </span>
                    </p>
                )}
            </div>
            <div
                className="wishlist-option"
                onClick={(e) =>
                    wishListFun(e, props?.id, props?.colorId, props?.wishList)
                }
            >
                {wishlistActive ? (
                    <svg
                        stroke="red"
                        fill="red"
                        stroke-width="0"
                        viewBox="0 0 1024 1024"
                        height="1.2em"
                        width="1.2em"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M923 283.6a260.04 260.04 0 0 0-56.9-82.8 264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9z"></path>
                    </svg>
                ) : (
                    <>
                        <svg
                            className="display-not-hover"
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 1024 1024"
                            height="1.2em"
                            width="1.2em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M923 283.6a260.04 260.04 0 0 0-56.9-82.8 264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z"></path>
                        </svg>
                        <svg
                            className="display-hover"
                            stroke="red"
                            fill="red"
                            stroke-width="0"
                            viewBox="0 0 1024 1024"
                            height="1.2em"
                            width="1.2em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M923 283.6a260.04 260.04 0 0 0-56.9-82.8 264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9z"></path>
                        </svg>
                    </>
                )}
            </div>
        </div>
    );
}

export default ProductBox;
