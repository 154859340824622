/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import FreeShipping from "../../assets/icons/free-shipping.webp";
import CardPayments from "../../assets/icons/card-payments.webp";
import EasyReturns from "../../assets/icons/easy-returns.webp";

function TermsConditions() {
    return (
        <div className="about-us pt-50 pb-50">
            <div className="container">
                <div className="col-xl-12 col-sm-12">
                    <h1>TERMS AND CONDITIONS</h1>
                    <div className="privacy mt-4">
                        <p>
                            Welcome to LIEVE! These terms and conditions govern
                            your use of our website, LIEVE.CARE, and any related
                            services offered by us. By accessing or using our
                            website, you agree to comply with these terms and
                            conditions in full. If you do not agree with any
                            part of these terms, please refrain from using our
                            website.
                        </p>

                        <h4 className="mt-4">Intellectual Property</h4>
                        <p>
                            All content, logos, trademarks, and other materials
                            displayed on our website are the property of LIEVE
                            and are protected by applicable intellectual
                            property laws. You may not use, reproduce, or
                            distribute any content from our website without
                            prior written consent.
                        </p>

                        <h4 className="mt-4">Use of Website:</h4>
                        <p>
                            You agree to use our website for lawful purposes
                            only and to refrain from engaging in any activity
                            that may disrupt or interfere with the functioning
                            of our website or infringe upon the rights of
                            others.
                        </p>
                        <p>
                            You may not use our website to transmit any harmful
                            or malicious content, including viruses, malware, or
                            spam.
                        </p>

                        <h4 className="mt-4">Product Information:</h4>
                        <p>
                            While we strive to provide accurate and up-to-date
                            information about our products, we do not warrant
                            the completeness or accuracy of the information
                            provided on our website. Product descriptions,
                            prices, and availability are subject to change
                            without notice.
                        </p>

                        <h4 className="mt-4">Limitation of Liability:</h4>
                        <p>
                            To the fullest extent permitted by law, LIEVE shall
                            not be liable for any direct, indirect, incidental,
                            special, or consequential damages arising out of or
                            in connection with your use of our website or the
                            products purchased through our website.
                        </p>

                        <h4 className="mt-4">Indemnification</h4>
                        <p>
                            You agree to indemnify and hold harmless LIEVE, its
                            affiliates, and their respective officers,
                            directors, employees, and agents from any and all
                            claims, liabilities, damages, and expenses
                            (including legal fees) arising out of or in
                            connection with your use of our website or any
                            violation of these terms and conditions.
                        </p>

                        <h4 className="mt-4">Governing Law:</h4>
                        <p>
                            These terms and conditions shall be governed by and
                            construed in accordance with the laws of [Your
                            Jurisdiction], without regard to its conflict of law
                            principles.
                        </p>

                        <h4 className="mt-4">
                            Changes to Terms and Conditions:
                        </h4>
                        <p>
                            We reserve the right to update or modify these terms
                            and conditions at any time without prior notice. Any
                            changes will be effective immediately upon posting
                            on this page. We encourage you to review this page
                            periodically for updates.
                        </p>
                    </div>
                </div>
            </div>

            {/* FEATURES */}
            <section className="feature-area pt-50">
                <div className="container">
                    <div className="feature-wrapper">
                        <div className="single-feature-col">
                            <div className="single-feature">
                                <div className="feature-icon">
                                    <img
                                        src={CardPayments}
                                        alt="Free Shipping"
                                    />
                                </div>
                                <div className="feature-content">
                                    <h4 className="title">Free Shipping</h4>
                                    <span className="sub-title">
                                        Free Domestic Shipping on purchase
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="single-feature-col">
                            <div className="single-feature">
                                <div className="feature-icon">
                                    <img
                                        src={FreeShipping}
                                        alt="Card Payments"
                                    />
                                </div>
                                <div className="feature-content">
                                    <h4 className="title">Secure Payments</h4>
                                    <span className="sub-title">
                                        Your payment information is processed
                                        securely.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="single-feature-col">
                            <div className="single-feature">
                                <div className="feature-icon">
                                    <img src={EasyReturns} alt="Easy Returns" />
                                </div>
                                <div className="feature-content">
                                    <h4 className="title">Customer Service</h4>
                                    <span className="sub-title">
                                        Responsive customer service via email,
                                        text or phone.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default TermsConditions;
