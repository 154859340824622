const pattern = [
  {
    id: 1,
    patternName: "Solid",
  },
  {
    id: 2,
    patternName: "Plain",
  },
  {
    id: 3,
    patternName: "Embroidered",
  },
];

export default pattern;