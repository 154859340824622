/* IMAGE IMPORTS */
import BurntOrange from "../assets/bestsellers/burnt-orange.webp";
import TurqoiseSequins from "../assets/bestsellers/turqoise-sequins.webp";
import SilverLame from "../assets/bestsellers/silver-lame-fabric.webp";
import WhiteOffwhite from "../assets/bestsellers/white-off-white-chiffon.webp";
import AllOverSilverSequins from "../assets/bestsellers/all-over-silver-sequins.webp";
import BestsellingRed from "../assets/bestsellers/bestselling-red-velvet-fabric.webp";

import PurpleVelvet from "../assets/new-arrivals/purple-velvet-stretch-fabric.webp";
import PinkVelvet from "../assets/new-arrivals/pink-velvet-all-over-sequins.webp";
import GorgeousSilver from "../assets/new-arrivals/gorgeous-silver-sequins.webp";
import GoldStretch from "../assets/new-arrivals/gold-stretch-velvet.webp";
import SixColorStretch from "../assets/new-arrivals/6-colors-stretch-velvet.webp";
import RoyalBlue from "../assets/new-arrivals/royal-blue-lame.webp";

const fabric = [
  {
    id: 1,
    fabricName: "Purple Velvet Stretch Fabric Solid",
    fabricImg: PurpleVelvet,
    fabricPrice: "$14.99",
    newBadge: true,
    colors: ["#FCB7AB", "#C37A8B", "#814D20", "#803E48"],
  },
  {
    id: 2,
    fabricName: "Pink Velvet Stretch-All Over Sequins",
    fabricImg: PinkVelvet,
    fabricPrice: "$14.99",
    newBadge: true,
  },
  {
    id: 3,
    fabricName: "Gorgeous Silver Sequins on Black Velvet",
    fabricImg: GorgeousSilver,
    fabricPrice: "$9.99",
    newBadge: true,
  },
  {
    id: 4,
    fabricName: "Gold Stretch Velvet with All Over Gold Sequins",
    fabricImg: GoldStretch,
    fabricPrice: "$9.99",
    newBadge: true,
  },
  {
    id: 5,
    fabricName: "6 Colors-Stretch Velvet with All Over Sequins",
    fabricImg: SixColorStretch,
    fabricPrice: "$9.99",
    newBadge: true,
  },
  {
    id: 6,
    fabricName: "Burnt Orange Velvet Stretch Fabric",
    fabricImg: BurntOrange,
    fabricPrice: "$9.99",
    newBadge: true,
  },
  {
    id: 7,
    fabricName: "Turqoise All Over Sequins Embroidery",
    fabricImg: TurqoiseSequins,
    fabricPrice: "$9.99",
    newBadge: true,
  },
  {
    id: 8,
    fabricName: "Silver Metallic Lame Fabric, 4 Way Stretch Foil Fabric",
    fabricImg: SilverLame,
    fabricPrice: "$9.99",
    newBadge: true,
  },
  {
    id: 9,
    fabricName: "White & Off White Chiffon Fabric",
    fabricImg: WhiteOffwhite,
    fabricPrice: "$9.99",
    newBadge: true,
  },
  {
    id: 10,
    fabricName: "All Over Silver Sequins on White Velvet",
    fabricImg: AllOverSilverSequins,
    fabricPrice: "$9.99",
    newBadge: true,
  },
  {
    id: 11,
    fabricName: "Bestselling RED Velvet with All Over Sequins",
    fabricImg: BestsellingRed,
    fabricPrice: "$9.99",
    newBadge: true,
  },
  {
    id: 12,
    fabricName: "Royal Blue Metallic Lame Fabric",
    fabricImg: RoyalBlue,
    fabricPrice: "$9.99",
    newBadge: true,
  },
];

export default fabric;