/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IMG_URL } from "../config";

function OrderPlaced() {
  const { cart, orderConfirmation } = useSelector((state) => state?.products);
  const [total, setTotal] = useState(0);
  const { user } = useSelector((state) => state?.user);

  useEffect(() => {
    let totalPrice = 0;
    cart?.map((obj) => {
      totalPrice = totalPrice + obj?.quantity * obj?.amount;
    });
    console.log("totalPrice: ", totalPrice);
    setTotal(totalPrice);
  }, [cart]);

  const [orderDetails, setOrderDetails] = useState("");
  useEffect(() => {
    console.log("orderConfirmation", orderConfirmation);
    setOrderDetails(orderConfirmation);
  }, [orderConfirmation]);
  useEffect(() => {
    axios.get("https://inspiroworld.roundtechsquare.com/shipstationxml.php", {
      headers: { "access-control-allow-origin": "*" },
    });
  }, [""]);

  return (
    <div className="order-placed mt-50">
      <div className="container">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-xl-8 col-sm-12">
            <div className="thanks-modal-content mb-50">
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="col-xl-12 col-sm-12">
                  <img
                    src={require("../assets/icons/order-placed.gif")}
                    width="100"
                    alt="Order Placed"
                  />
                  <h3>Your order {orderDetails?.orderId} has been placed!</h3>
                  <p>
                    We sent an email to {user?.email} with your order
                    confirmation and receipt. If the email hasn't arrived within
                    two minutes, please check your spam folder to see if the
                    email was routed there.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderPlaced;
