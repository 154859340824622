import React from "react"
import FreeShipping from "../../assets/icons/free-shipping.webp"
import CardPayments from "../../assets/icons/card-payments.webp"
import EasyReturns from "../../assets/icons/easy-returns.webp"

function ShippingReturnPolicy() {
  return (
    <div className="about-us pt-50 pb-50">
      <div className="container">
        <div className="col-xl-12 col-sm-12">
          <h1>SHIPPING AND RETURN POLICY</h1>
          <div className="shipping mt-4">
            <h4>SHIPPING POLICY</h4>
            <p className="mt-3">
              <b style={{fontWeight: "800"}}>FREE SHIPPING - </b>We offer FREE
              DOMESTIC SHIPPING with purchase of $75.{" "}
            </p>
            <p>
              All the orders are usually processed and shipped within 1-2
              business days from Monday–Friday (excluding weekends and
              holidays). Deliveries may take 2-9 business days depending upon
              the shipping services chosen by the Customer.
            </p>
            <p>
              We ship to all the 50 States as well as Internationally. Shipping
              charges will be added to your invoice upon your agreement during
              the checkout process.{" "}
            </p>
            <p>
              <b style={{fontWeight: "800"}}>SWATCH SHIPPING- </b>All the
              swatches orders are processed and shipped within 1-3 business days
              (except weekends and holidays).
            </p>
            <p>
              <b>EXPEDITED SHIPPING SERVICE- </b>All orders placed before 1 PM
              EST for expedited shipping services (including next day air, 2nd
              day air and 3 day select) will be shipped on the same day. All
              orders after 1 PM EST will be shipped on the following day. If
              there are any delays in expedited shipping services caused by the
              carrier, we will work together with the customer to resolve the
              issue to the best of our ability however we cannot make any
              guarantees as it is entirely up to the carrier for reimbursement.
            </p>
            <p>
              <b style={{fontWeight: "800"}}>INTERNATIONAL SHIPPING- </b>We ship
              Worldwide via USPS, FeDex, UPS services. Please note, any and all
              customs charges for international orders will potentially be
              subject to customs duties and taxes depending on the destination
              country. By placing your order, you are agreeing to bear these
              charges upon the package's arrival (Please note that
              Inspiroworld.com has no control over this charges and they are the
              sole responsibility of the person placing the order).
            </p>
            <p>
              If you have any questions or requests regarding your shipping,
              please contact us at{" "}
              <a href="mailto:info@lieve.care" style={{textDecoration: "none"}}>
                info@lieve.care
              </a>
            </p>
          </div>
          <div className="return-policy mt-5">
            <h4>RETURN POLICY</h4>
            <p className="mt-3">
              We always encourage our valuable customers to order{" "}
              <b style={{fontWeight: "800"}}>
                swatches to ensure the color, design and feel the fabric
              </b>
              , though it takes a while to receive your final order but at the
              same time it gives you confidence about your order.
            </p>
            <p>
              All the sales are final as the fabric is custom cut to your
              specifications. However, if you receive any damaged, defective or
              wrong items, contact us immediately.
            </p>
            <p>
              Kindly check your item(s) immediately on delivery. As per our
              policy customers have 7 days (from delivery) to claim for
              return/exchange or refund.
            </p>
            <ul>
              <li>Claims made later will be considered null or void.</li>
              <li>
                We will not process a return for items you send back to us
                without prior communication.
              </li>
            </ul>
            <p>
              All returned items must be uncut unwashed, unused, unaltered and
              in its original packaging. To complete your
              return/exchange/refund, we do require a receipt or proof of
              purchase. Shipping fees are non-refundable, and you will be
              responsible for return shipping costs. Once your item(s) is
              received, inspected and accepted, we will notify you through an
              email. Refunds are typically issued 7-15 days after the item(s)
              are received and accepted
            </p>
            <p style={{fontWeight: "800"}}>
              The following products are ineligible to be returned:
            </p>
            <ul>
              <li style={{fontWeight: "500"}}>Clearance Items</li>
              <li style={{fontWeight: "500"}}>Fabric Samples/Swatches</li>
            </ul>
            <p>
              Please contact us at{" "}
              <a href="mailto:info@lieve.care" style={{textDecoration: "none"}}>
                info@lieve.care
              </a>{" "}
              or{" "}
              <a href="tel:+919499777708" style={{textDecoration: "none"}}>
                (91)9499777708
              </a>{" "}
              if you have any questions about the returns.
            </p>
          </div>
        </div>
      </div>

      {/* FEATURES */}
      <section className="feature-area pt-50">
        <div className="container">
          <div className="feature-wrapper">
            <div className="single-feature-col">
              <div className="single-feature">
                <div className="feature-icon">
                  <img src={CardPayments} alt="Free Shipping" />
                </div>
                <div className="feature-content">
                  <h4 className="title">Free Shipping</h4>
                  <span className="sub-title">
                    Free Domestic Shipping on purchase
                    <br />
                    of $75+.
                  </span>
                </div>
              </div>
            </div>
            <div className="single-feature-col">
              <div className="single-feature">
                <div className="feature-icon">
                  <img src={FreeShipping} alt="Card Payments" />
                </div>
                <div className="feature-content">
                  <h4 className="title">Secure Payments</h4>
                  <span className="sub-title">
                    Your payment information is processed securely.
                  </span>
                </div>
              </div>
            </div>
            <div className="single-feature-col">
              <div className="single-feature">
                <div className="feature-icon">
                  <img src={EasyReturns} alt="Easy Returns" />
                </div>
                <div className="feature-content">
                  <h4 className="title">Customer Service</h4>
                  <span className="sub-title">
                    Responsive customer service via email, text or phone.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ShippingReturnPolicy
