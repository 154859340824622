/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
    register,
    login,
    resendOtp,
    verifyOtp,
    loginVerifyEmail,
    forgotPassword,
    addTocart,
} from "../service/api";
import { useDispatch, useSelector } from "react-redux";
import actions from "../redux/user/actions";
import { useNavigate } from "react-router-dom";
import { Spinner, Button } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useUpdateCart } from "../hooks/useUpdateCart";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { toast } from "react-toast";

function Registration() {
    const { enqueueSnackbar } = useSnackbar();
    const { setUpdateCart } = useUpdateCart();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { cart } = useSelector((state) => state?.products);
    const [input, setInput] = useState({
        name: "",
        email: "",
        password: "",
        phoneNumber: 0,
    });
    const [showLoginPassword, setShowLoginPassword] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showRegisterPassword, setRegisterPassword] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(0);
    const [inputReg, setInputReg] = useState({
        name: false,
        email: false,
        password: false,
        phoneNumber: false,
    });

    const [loginInput, setLoginInput] = useState({
        name: "",
        email: "",
        password: "",
    });

    const [inputLogin, setInputLogin] = useState({
        name: false,
        email: false,
        password: false,
    });

    const [otp, setOtp] = useState(null);
    const [otpError, setOtpError] = useState(false);

    const tabs = {
        REGISTER: "REGISTER",
        VERIFY: "VERIFY",
    };

    const loginTabs = {
        LOGIN: "LOGIN",
        VERIFY: "VERIFY",
        FORGOT_PASSWORD: "FORGOT_PASSWORD",
        SEND_OTP: "SEND_OTP",
        SEND_PASSWORD: "SEND_PASSWORD",
    };

    const [activeTab, setactiveTab] = useState(tabs.REGISTER);
    const [loginActiveTab, setLoginActiveTab] = useState(loginTabs.LOGIN);

    const onRegchange = (e) => {
        console.log("e: ", e);
        setInput({ ...input, [e.target.name]: e.target.value });
        setInputReg({ ...inputReg, [e.target.name]: false });
    };
    const onLogchange = (e) => {
        setLoginInput({ ...loginInput, [e.target.name]: e.target.value });
        setInputLogin({ ...inputLogin, [e.target.name]: false });
    };

    function emailValidation(body) {
        const regex =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (regex.test(body) === false) {
            return false;
        }
        return true;
    }

    const [loadingReg, setloadingReg] = useState(false);

    const registerForm = (e) => {
        e.preventDefault();
        if (!input?.name) {
            setInputReg({ ...inputReg, name: true });
            // enqueueSnackbar("Please enter your name");
            return;
        }
        if (phoneNumber === 0 || !input?.phoneNumber) {
            setInputReg({ ...inputReg, phoneNumber: true });
            // enqueueSnackbar("Please enter your name");
            return;
        } else {
            setInputReg({ ...inputReg, phoneNumber: false });
        }
        if (!input?.email) {
            // enqueueSnackbar("Please enter email");
            setInputReg({ ...inputReg, email: true });
            return;
        }
        if (!emailValidation(input?.email)) {
            // enqueueSnackbar("Please enter valid email");
            setInputReg({ ...inputReg, email: true });
            return;
        }
        if (!input?.password) {
            // enqueueSnackbar("Please enter your password");
            setInputReg({ ...inputReg, password: true });
            return;
        }

        const submitForm = new FormData();
        submitForm.append("name", input.name);
        submitForm.append("email", input.email);
        submitForm.append("password", input.password);
        submitForm.append("mobile", input.phoneNumber);
        setloadingReg(true);
        register(submitForm)
            .then((res) => {
                console.log("res--->", res);
                if (res?.status === false) {
                    enqueueSnackbar(res?.message, { variant: "error" });
                } else if (res?.status === true) {
                    enqueueSnackbar(res?.message, { variant: "success" });
                    dispatch(actions.setUser(res?.data?.userData));
                    dispatch(actions.setToken(res?.data?.token));
                    localStorage.setItem("authenticated", true);
                    localStorage.setItem("token", res?.data?.token);
                    setactiveTab(tabs.VERIFY);
                    setLoginActiveTab(loginTabs.LOGIN);
                }
            })
            .catch((e) => {
                console.log("error--->", e);
                enqueueSnackbar(e?.response?.data?.message, {
                    variant: "error",
                });
            })
            .finally((res) => {
                setloadingReg(false);
            });
    };

    const resendOtpFun = (e) => {
        e.preventDefault();
        if (!input?.email) {
            enqueueSnackbar("Please enter email");
            return;
        }
        if (!emailValidation(input?.email)) {
            enqueueSnackbar("Please enter valid email");
            return;
        }
        const submitForm = new FormData();
        submitForm.append("email", input.email);
        setloadingReg(true);
        resendOtp(submitForm)
            .then((res) => {
                console.log("res--->", res);
                if (res?.status === false) {
                    enqueueSnackbar(res?.message);
                } else if (res?.status === true) {
                    enqueueSnackbar(res?.message);
                }
            })
            .catch((e) => {
                console.log("error--->", e);
                enqueueSnackbar(e?.response?.data?.message);
            })
            .finally((res) => {
                setloadingReg(false);
            });
    };

    const sendOtpReg = (e) => {
        e.preventDefault();
        if (!otp) {
            // enqueueSnackbar("Please enter 6 digit otp");
            setOtpError(true);
            return;
        }
        const submitForm = new FormData();
        submitForm.append("code", otp);
        console.log("send otp");
        setloadingReg(true);
        verifyOtp(submitForm)
            .then((res) => {
                console.log("res--->", res);
                if (res?.status === false) {
                    enqueueSnackbar(res?.message, { variant: "error" });
                } else if (res?.status === true) {
                    enqueueSnackbar(res?.message, { variant: "success" });
                    navigate("/");
                }
            })
            .catch((e) => {
                console.log("error--->", e);
                enqueueSnackbar(e?.response?.data?.message, {
                    variant: "error",
                });
            })
            .finally((res) => {
                setOtp("");
                setloadingReg(false);
            });
    };

    const [loading, setLoading] = useState(false);
    const [mobile, setMobile] = useState("");
    const loginForm = (e) => {
        e.preventDefault();
        console.log(
            "%c Line:232 🍤 mobile",
            "color:#ffdd4d",
            mobile,
            mobile.split("+91"),
        );
        let phone = mobile.split("+91")[1];
        console.log("%c Line:233 🍧 phone", "color:#3f7cff", phone);
        if (!phone || phone.length != 10) {
            toast.error("Please enter Valid Mobile Number");
            // enqueueSnackbar("Please enter email");
            // setInputLogin({ ...inputLogin, email: true });
            return;
        }
        // if (!emailValidation(loginInput?.email)) {
        //     // enqueueSnackbar("Please enter valid email");
        //     setInputLogin({ ...inputLogin, email: true });
        //     return;
        // }
        // if (!loginInput?.password) {
        //     // enqueueSnackbar("Please enter your password");
        //     setInputLogin({ ...inputLogin, password: true });
        //     return;
        // }

        const submitForm = new FormData();
        submitForm.append("mobile", phone);
        setLoading(true);
        login(submitForm)
            .then((res) => {
                console.log("res--->", res);
                if (res?.status === false) {
                    enqueueSnackbar(res?.message, { variant: "error" });
                } else if (res?.status === true) {
                    enqueueSnackbar(res?.message, { variant: "success" });
                    // dispatch(actions.setUser(res?.data?.userData));
                    // dispatch(actions.setToken(res?.data?.token));
                    // localStorage.setItem("authenticated", true);
                    // localStorage.setItem("token", res?.data?.token);

                    // if (!res?.data?.userData?.isEmailVerified) {
                    // loginVerifyEmail({ email: loginInput.email }).then(
                    //     (res) => {
                    //         console.log("verify email--->", res);
                    //     },
                    // );
                    setLoginActiveTab(loginTabs.VERIFY);
                    setactiveTab(tabs.REGISTER);
                    return;
                    // } else {
                    //     dispatch(actions.setLoggedIn(true));
                    //     if (cart && cart?.length > 0) {
                    //         cart?.map((cartItem) => {
                    //             let body = {
                    //                 product: cartItem?.product?._id,
                    //                 color: cartItem?.color?._id,
                    //                 quantity: cartItem?.quantity,
                    //             };
                    //             addTocart(body).then((res) => {
                    //                 // enqueueSnackbar(res?.message);
                    //                 if (res?.status) {
                    //                     setUpdateCart(true);
                    //                 }
                    //             });
                    //         });
                    //     }

                    //     navigate("/");
                    // }
                }
            })
            .catch((e) => {
                console.log("error--->", e);
                enqueueSnackbar(e?.response?.data?.message, {
                    variant: "error",
                });
            })
            .finally((res) => {
                setLoading(false);
            });
    };

    const resendLoginOtpFun = (e) => {
        e.preventDefault();
        if (!loginInput?.email) {
            // enqueueSnackbar("Please enter email");
            setInputLogin({ ...inputLogin, email: true });
            return;
        }
        if (!emailValidation(loginInput?.email)) {
            // enqueueSnackbar("Please enter valid email");
            setInputLogin({ ...inputLogin, email: true });
            return;
        }

        const submitForm = new FormData();
        submitForm.append("email", loginInput.email);
        setLoading(true);
        resendOtp(submitForm)
            .then((res) => {
                console.log("res--->", res);
                if (res?.status === false) {
                    enqueueSnackbar(res?.message, { variant: "error" });
                } else if (res?.status === true) {
                    enqueueSnackbar(res?.message, { variant: "success" });
                }
            })
            .catch((e) => {
                console.log("error--->", e);
                enqueueSnackbar(e?.response?.data?.message, {
                    variant: "error",
                });
            })
            .finally((res) => {
                setLoading(false);
            });
    };

    const sendOtp = (e) => {
        e.preventDefault();
        if (!otp) {
            // enqueueSnackbar("Please enter 6 digit otp");
            setOtpError(true);
            return;
        }
        let phone = mobile.split("+91")[1];
        const submitForm = new FormData();
        submitForm.append("code", otp);
        submitForm.append("mobile", phone);
        console.log("send otp");
        setLoading(true);
        verifyOtp(submitForm)
            .then((res) => {
                console.log("%c Line:362 🍓 res", "color:#7f2b82", res);
                // console.log("res--->", res);
                if (res?.status === false) {
                    enqueueSnackbar(res?.message, { variant: "error" });
                } else if (res?.status === true) {
                    enqueueSnackbar(res?.message, { variant: "success" });
                    dispatch(actions.setUser(res?.data?.userData));
                    dispatch(actions.setToken(res?.data?.token));
                    localStorage.setItem("authenticated", true);
                    localStorage.setItem("token", res?.data?.token);
                    dispatch(actions.setLoggedIn(true));
                    if (cart && cart?.length > 0) {
                        cart?.map((cartItem) => {
                            let body = {
                                product: cartItem?.product?._id,
                                color: cartItem?.color?._id,
                                quantity: cartItem?.quantity,
                            };
                            addTocart(body).then((res) => {
                                // enqueueSnackbar(res?.message);
                                if (res?.status) {
                                    setUpdateCart(true);
                                }
                            });
                        });
                    }

                    navigate("/");
                }
            })
            .catch((e) => {
                console.log("error--->", e);
                enqueueSnackbar(e?.response?.data?.message, {
                    variant: "error",
                });
            })
            .finally((res) => {
                setOtp("");
                setLoading(false);
            });
    };

    const forgotPasswordFun = (e) => {
        e.preventDefault();
        if (!loginInput?.email) {
            // enqueueSnackbar("Please enter email");
            setInputLogin({ ...inputLogin, email: true });
            return;
        }
        if (!emailValidation(loginInput?.email)) {
            // enqueueSnackbar("Please enter valid email");
            setInputLogin({ ...inputLogin, email: true });
            return;
        }
        if (!loginInput?.password) {
            // enqueueSnackbar("Please enter your password");
            setInputLogin({ ...inputLogin, password: true });
            return;
        }

        const submitForm = new FormData();
        submitForm.append("email", loginInput.email);
        submitForm.append("password", loginInput.password);
        setLoading(true);
        forgotPassword(submitForm)
            .then((res) => {
                console.log("res--->", res);
                if (res?.status === false) {
                    enqueueSnackbar(res?.message, { variant: "error" });
                } else if (res?.status === true) {
                    enqueueSnackbar(res?.message, { variant: "success" });
                    setLoginActiveTab(loginTabs.LOGIN);
                    setLoginInput({
                        ...loginInput,
                        name: "",
                        email: "",
                        password: "",
                    });
                }
            })
            .catch((e) => {
                console.log("error--->", e);
                enqueueSnackbar(e?.response?.data?.message, {
                    variant: "error",
                });
            })
            .finally((res) => {
                setLoading(false);
            });
    };

    const [otpMatch, setOtpMatch] = useState(null);
    const sendOtpToMail = (e) => {
        e.preventDefault();
        if (!loginInput?.email) {
            // enqueueSnackbar("Please enter email");
            setInputLogin({ ...inputLogin, email: true });
            return;
        }
        if (!emailValidation(loginInput?.email)) {
            // enqueueSnackbar("Please enter valid email");
            setInputLogin({ ...inputLogin, email: true });
            return;
        }
        // if (!loginInput?.password) {
        //   // enqueueSnackbar("Please enter your password");
        //   setInputLogin({ ...inputLogin, password: true })
        //   return
        // }

        const submitForm = new FormData();
        submitForm.append("email", loginInput.email);
        // submitForm.append("password", loginInput.password);
        setLoading(true);
        loginVerifyEmail(submitForm)
            .then((res) => {
                console.log("res--->", res);
                if (res?.status === false) {
                    enqueueSnackbar(res?.message, { variant: "error" });
                } else if (res?.status === true) {
                    enqueueSnackbar(res?.message, { variant: "success" });
                    setOtpMatch(res?.data);
                    setLoginActiveTab(loginTabs.SEND_OTP);
                    // setLoginInput({ ...loginInput, name: '', email: '', password: '' })
                }
            })
            .catch((e) => {
                console.log("error--->", e);
                enqueueSnackbar(e?.response?.data?.message);
            })
            .finally((res) => {
                setLoading(false);
            });
    };

    const verifyOtpToChangePassword = (e) => {
        e.preventDefault();
        if (!otp) {
            // enqueueSnackbar("Please enter 6 digit otp");
            setOtpError(true);
            return;
        }
        if (otp === otpMatch) {
            setLoginActiveTab(loginTabs.SEND_PASSWORD);
            enqueueSnackbar("OTP verified successfully", {
                variant: "success",
            });
        } else {
            enqueueSnackbar("The OTP enetered is incorrect", {
                variant: "error",
            });
        }
    };

    return (
        <div className="register">
            <section className="pb-50 pt-50">
                <div className="container">
                    <div className="row">
                        {/* LOGIN FORM */}
                        {loading ? (
                            <div
                                className="col-lg-6 col-sm-12"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Spinner />
                            </div>
                        ) : loginActiveTab === loginTabs.LOGIN ? (
                            <div className="col-lg-12 col-sm-12">
                                <div className="login_wrap">
                                    <h3 className="mb-30">
                                        Login to your Account
                                    </h3>
                                    <form className="login-form">
                                        <div className="form-group">
                                            <PhoneInput
                                                placeholder="Phone"
                                                defaultCountry="IN"
                                                value={phoneNumber}
                                                onChange={(e) => {
                                                    setMobile(e);
                                                }}
                                                className="phone-number-select"
                                            />
                                        </div>

                                        {/* <div className="form-group">
                                            <input
                                                value={loginInput?.password}
                                                onChange={onLogchange}
                                                type={
                                                    showLoginPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                name="password"
                                                className="form-control"
                                                placeholder="Password"
                                            />
                                            <Button
                                                className="password-icon"
                                                onClick={() =>
                                                    setShowLoginPassword(
                                                        !showLoginPassword,
                                                    )
                                                }
                                            >
                                                {showLoginPassword ? (
                                                    <AiFillEyeInvisible />
                                                ) : (
                                                    <AiFillEye />
                                                )}
                                            </Button>
                                            <small
                                                className="validation-error"
                                                style={{ color: "red" }}
                                            >
                                                {inputLogin?.password
                                                    ? "password is required"
                                                    : ""}
                                            </small>
                                        </div> */}

                                        {/* <div className="forgot-pass">
                                            <p
                                                onClick={() => {
                                                    setLoginActiveTab(
                                                        loginTabs.FORGOT_PASSWORD,
                                                    );
                                                }}
                                            >
                                                Forgot Password ?
                                            </p>
                                        </div> */}
                                        <button
                                            className="login-btn"
                                            onClick={loginForm}
                                        >
                                            Sing up
                                        </button>
                                    </form>
                                </div>
                            </div>
                        ) : (
                            <div className="col-lg-6 col-sm-12">
                                <div className="login_wrap">
                                    <h3 className="mb-30">Verify Mobile</h3>

                                    <form className="login-form">
                                        <div className="form-group">
                                            <input
                                                value={otp}
                                                onChange={(e) => {
                                                    setOtpError(false);
                                                    setOtp(e.target.value);
                                                }}
                                                type="number"
                                                name="otp"
                                                className="form-control"
                                                placeholder="Enter 4 digit OTP"
                                            />
                                            <small
                                                className="validation-error"
                                                style={{ color: "red" }}
                                            >
                                                {otpError
                                                    ? "Please enter OTP"
                                                    : ""}
                                            </small>
                                            <span className="forgot-pass">
                                                <p onClick={resendLoginOtpFun}>
                                                    Resend OTP
                                                </p>
                                            </span>
                                        </div>
                                        <button
                                            className="login-btn"
                                            onClick={sendOtp}
                                        >
                                            Verify Mobile
                                        </button>
                                    </form>
                                </div>
                            </div>
                        )}
                        {/* REGISTRATION FORM */}
                        {/* {loadingReg ? (
              <div
                className="col-lg-6 col-sm-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Spinner />
              </div>
            ) : activeTab === tabs.REGISTER ? (
              <div className="col-lg-6 col-sm-12">
                <div className="login_wrap">
                  <h3 className="mb-30">Create an Account</h3>
                  <p className="mb-30">
                    Your personal data will be used to support your experience
                    throughout this website, to manage access to your account.
                  </p>
                  <form className="login-form">
                    <div className="form-group">
                      <input
                        value={input?.name}
                        onChange={onRegchange}
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                      />
                      <small
                        className="validation-error"
                        style={{color: "red"}}>
                        {inputReg?.name ? "Name is required" : ""}
                      </small>
                    </div>

                    <div className="form-group">
                      <PhoneInput
                        placeholder="Phone"
                        defaultCountry="IN"
                        value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e)
                          onRegchange({
                            target: {name: "phoneNumber", value: e},
                          })
                        }}
                        className="phone-number-select"
                      />
                      <small
                        className="validation-error"
                        style={{color: "red"}}>
                        {inputReg?.phoneNumber
                          ? "Phone number is required"
                          : ""}
                      </small>
                    </div>

                    <div className="form-group">
                      <input
                        value={input?.email}
                        onChange={onRegchange}
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Your Email"
                      />
                      <small
                        className="validation-error"
                        style={{color: "red"}}>
                        {inputReg?.email ? "email is required" : ""}
                      </small>
                    </div>

                    <div className="form-group">
                      <input
                        value={input?.password}
                        onChange={onRegchange}
                        type={showRegisterPassword ? "text" : "password"}
                        name="password"
                        className="form-control"
                        placeholder="Password"
                      />
                      <Button
                        className="password-icon"
                        onClick={() =>
                          setRegisterPassword(!showRegisterPassword)
                        }>
                        {showRegisterPassword ? (
                          <AiFillEyeInvisible />
                        ) : (
                          <AiFillEye />
                        )}
                      </Button>
                      <small
                        className="validation-error"
                        style={{color: "red"}}>
                        {inputReg?.password ? "password is required" : ""}
                      </small>
                    </div>

                    <button className="login-btn" onClick={registerForm}>
                      Register
                    </button>
                  </form>
                </div>
              </div>
            ) : (
              <div className="col-lg-6 col-sm-12">
                <div className="login_wrap">
                  <h3 className="mb-30">Verify Email</h3>
                  <form className="login-form">
                    <div className="form-group">
                      <input
                        value={otp}
                        onChange={(e) => {
                          setOtpError(false)
                          setOtp(e.target.value)
                        }}
                        type="number"
                        name="otp"
                        className="form-control"
                        placeholder="Enter 6 digit OTP"
                      />
                      <small
                        className="validation-error"
                        style={{color: "red"}}>
                        {otpError ? "Please enter OTP" : ""}
                      </small>
                      <h5
                        style={{
                          fontSize: "15px",
                          marginTop: "10px",
                          textAlign: "end",
                        }}>
                        Didn't receive code?
                        <span
                          onClick={resendOtpFun}
                          style={{
                            fontSize: "15px",
                            cursor: "pointer",
                            marginLeft: "5px",
                            textDecoration: "underline",
                          }}>
                          Request again
                        </span>
                      </h5>
                    </div>
                    <button className="login-btn" onClick={sendOtpReg}>
                      Verify Email
                    </button>
                  </form>
                </div>
              </div>
            )} */}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Registration;
