/* eslint-disable no-unused-vars */
import React from "react";
import Banner from "../components/Banner";
import { Button } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

/* COMPONENT IMPORTS */
import InstagramWall from "../components/home/InstagramWall";
import NewArrivals from "../components/home/NewArrivals";
import Categories from "../components/home/Categories";
import CategoriesNew from "../components/home/CategoriesNew";

/* IMAGE IMPORTS */
import FreeShipping from "../assets/icons/free-shipping.webp";
import CardPayments from "../assets/icons/card-payments.webp";
import EasyReturns from "../assets/icons/easy-returns.webp";
import EarnPoints from "../assets/icons/earn-points.webp";
import Purchase from "../assets/icons/purchase.webp";
import Rewards from "../assets/icons/rewards.webp";
import RewardsRightImage from "../assets/rewards-right-img.webp";

function Homepage() {
  const navigate = useNavigate();
  return (
    <div>
      {/* BANNERS */}
      <Banner />

      {/* CATEGORIES */}
      <Categories />

      {/* NEW ARRIVALS */}
      {/* <NewArrivals /> */}

      {/* CLEARANCE SALE */}
      {/* <section className="clearance-sale mb-50">
          <div className="container">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-xl-6 col-sm-12">
                <div className="left-text">
                  <h2>SHOP CLEARANCE FABRICS</h2>
                  <p>
                    Everyone loves a bargain! If you thought our prices were
                    already great, then you need to check out our sales &amp;
                    clearance pages.
                  </p>
                  <Button className="sale-shop-button" onClick={() =>{
                    navigate('/products/tag=638dcee1d0e1bf2d03b0022b+Sale')
                  }}>SHOP NOW</Button>
                  
                </div>
              </div>
            </div>
          </div>
        </section> */}

      {/* INSTAGRAM WALL */}
      {/* <InstagramWall /> */}

      {/* REWARDS NEW */}
      {/* <section className="new-rewards-section mb-50">
        <div className="container">
          <div className="rewards-with-bg">
            <div className="row">
              <div className="col-xl-6 col-sm-12">
                <div className="left-rewards">
                  <h2>IT'S THE SEASON FOR REWARDS</h2>
                  <h5>Earn Points on your purchase.</h5>
                  <h5>Collect points and redeem them as you shop!</h5>
                  <div className="feature-icons">
                    <div className="features-section-one">
                      <img src={Purchase} width="55" alt="Purchase" />
                      <h5>PURCHASE</h5>
                    </div>
                    <div className="border-middle" />
                    <div
                      className="features-section-one"
                      style={{ marginLeft: "35px" }}
                    >
                      <img src={EarnPoints} width="55" alt="Earn Points" />
                      <h5>EARN POINTS</h5>
                    </div>
                    <div className="border-middle" />
                    <div className="features-section-one">
                      <img src={Rewards} width="55" alt="Get Rewards" />
                      <h5>GET REWARDS</h5>
                    </div>
                  </div>
                  <Link to="/rewards">
                                        <Button className="learn-more-button">
                                            LEARN MORE
                                        </Button>
                                    </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* FEATURES */}
      {/* <section className="feature-area pb-50">
        <div className="container">
          <div className="feature-wrapper">
            <div className="single-feature-col">
              <div className="single-feature">
                <div className="feature-icon">
                  <img src={FreeShipping} alt="Free Shipping" />
                </div>
                <div className="feature-content">
                  <h4 className="title">Free Shipping</h4>
                </div>
              </div>
            </div>
            <div className="single-feature-col">
              <div className="single-feature">
                <div className="feature-icon">
                  <img src={CardPayments} alt="Card Payments" />
                </div>
                <div className="feature-content">
                  <h4 className="title">Easy Payments</h4>
                </div>
              </div>
            </div>
            <div className="single-feature-col">
              <div className="single-feature">
                <div className="feature-icon">
                  <img src={EasyReturns} alt="Easy Returns" />
                </div>
                <div className="feature-content">
                  <h4 className="title">NO Returns</h4>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default Homepage;
