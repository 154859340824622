import React from "react";
import ReactDOM from "react-dom/client";
import App from "./routes/App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <App />
        <a
            href="https://wa.me/9499777708"
            class="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
        >
            <i class="fa fa-whatsapp whatsapp-icon"></i>
        </a>
    </React.StrictMode>,
);
