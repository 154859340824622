const actions = {
  GET_COLOR: "GET_COLOR",
  GET_TYPE: "GET_TYPE",
  GET_COMPOSITION: "GET_COMPOSITION",
  GET_PATTERN: "GET_PATTERN",
  SET_PARAMS: "SET_PARAMS",
  SET_SELECTED_PRODUCT_ID: "SET_SELECTED_PRODUCT_ID",
  SET_SELECTED_PRODUCT: "SET_SELECTED_PRODUCT",
  SET_WISHLIST: "SET_WISHLIST",
  SET_SEARCH: "SET_SEARCH",
  CART_PRODUCTS: "CART_PRODUCTS",
  SET_TAG: "SET_TAG",
  SET_MODAL: "SET_MODAL",
  LOGOUT: "LOGOUT",
  SET_ORDER_DETAILS: "SET_ORDER_DETAILS",
  SET_ORDER_CONFIRMATION: "SET_ORDER_CONFIRMATION",
  SET_WISHLIST_DATA: "SET_WISHLIST_DATA",
  SET_WISHLIST_TRIGGER: "SET_WISHLIST_TRIGGER",
  setColor: (data) => {
    return {
      type: actions.GET_COLOR,
      data,
    };
  },
  setType: (data) => {
    return {
      type: actions.GET_TYPE,
      data,
    };
  },
  setComposition: (data) => {
    return {
      type: actions.GET_COMPOSITION,
      data,
    };
  },
  setPattern: (data) => {
    return {
      type: actions.GET_PATTERN,
      data,
    };
  },
  setParams: (data) => {
    return {
      type: actions.SET_PARAMS,
      data,
    };
  },
  setSelectedProductId: (data) => {
    return {
      type: actions.SET_SELECTED_PRODUCT_ID,
      data,
    };
  },
  setSelectedProduct: (data) => {
    return {
      type: actions.SET_SELECTED_PRODUCT,
      data,
    };
  },
  setWishList: (data) => {
    return {
      type: actions.SET_WISHLIST,
      data,
    };
  },
  setSearch: (data) => {
    return {
      type: actions.SET_SEARCH,
      data,
    };
  },
  setCartData: (data) => {
    return {
      type: actions.CART_PRODUCTS,
      data,
    };
  },
  setTag: (data) => {
    return {
      type: actions.SET_TAG,
      data,
    };
  },
  setModal: (data) => {
    return {
      type: actions.SET_MODAL,
      data,
    };
  },
  logout: (data) => {
    return {
      type: actions.LOGOUT,
      data,
    };
  },
  setOrderDetails: (data) => {
    return {
      type: actions.SET_ORDER_DETAILS,
      data,
    };
  },
  setOrderConfirmation: (data) => {
    return {
      type: actions.SET_ORDER_CONFIRMATION,
      data,
    };
  },
  setWishlistData: (data) => {
    return {
      type: actions.SET_WISHLIST_DATA,
      data,
    };
  },
  setWishlistTrigger: (data) => {
     return {
      type: actions.SET_WISHLIST_TRIGGER,
      data,
    };
  },
};

export default actions;
