/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import FreeShipping from "../../assets/icons/free-shipping.webp";
import CardPayments from "../../assets/icons/card-payments.webp";
import EasyReturns from "../../assets/icons/easy-returns.webp";

function PrivacyPolicy() {
    return (
        <div className="about-us pt-50 pb-50">
            <div className="container">
                <div className="col-xl-12 col-sm-12">
                    <h1>PRIVACY POLICY</h1>
                    <div className="privacy mt-4">
                        <p>
                            At LIEVE, we are committed to protecting your
                            privacy and ensuring the security of your personal
                            information. This Privacy Policy outlines how we
                            collect, use, and safeguard the data you provide to
                            us when using our website, LIEVE.CARE, and any
                            related services.
                        </p>

                        <h4 className="mt-4">Information we collect</h4>
                        <p>
                            Personal Information: When you make a purchase or
                            register an account on our website, we may collect
                            personal information such as your name, email
                            address, shipping address, and payment details.
                        </p>
                        <p>
                            Usage Data: We may also collect information about
                            how you interact with our website, including your
                            browsing activities, IP address, browser type,
                            device identifiers, and other usage data.
                        </p>

                        <h4 className="mt-4">How we use your information</h4>
                        <ul>
                            <li>
                                Order Processing: We use your personal
                                information to process your orders, communicate
                                with you regarding your purchases, and provide
                                customer support.
                            </li>
                            <li>
                                Personalization: We may use your information to
                                personalize your shopping experience, recommend
                                products that may be of interest to you, and
                                tailor our marketing communications accordingly.
                            </li>
                            <li>
                                Analytics: We analyze usage data to improve our
                                website, optimize performance, and enhance the
                                user experience.
                            </li>
                        </ul>

                        <h4 className="mt-4">Data Security:</h4>
                        <p>
                            We employ industry-standard security measures to
                            protect your personal information from unauthorized
                            access, disclosure, alteration, or destruction.
                        </p>
                        <p>
                            Your payment information is encrypted using secure
                            socket layer technology (SSL) and stored securely in
                            compliance with Payment Card Industry Data Security
                            Standards (PCI-DSS).
                        </p>

                        <h4 className="mt-4">Data Sharing and Disclosure:</h4>
                        <p>
                            We may share your personal information with
                            third-party service providers who assist us in
                            operating our website, processing payments, or
                            delivering products to you. These service providers
                            are contractually obligated to safeguard your
                            information and may only use it for the purposes
                            specified by us.
                        </p>
                        <p>
                            We may also disclose your information in response to
                            legal requests, court orders, or to comply with
                            applicable laws and regulations.{" "}
                        </p>

                        <h4 className="mt-4">Your Rights:</h4>
                        <p>
                            You have the right to access, update, or delete your
                            personal information at any time. You may also
                            opt-out of receiving marketing communications from
                            us by following the instructions provided in the
                            emails we send you.
                        </p>

                        <h4 className="mt-4">Changes to This Privacy Policy</h4>
                        <p>
                            We reserve the right to update or modify this
                            Privacy Policy at any time. Any changes will be
                            reflected on this page, and we encourage you to
                            review this policy periodically for updates.
                        </p>
                    </div>
                </div>
            </div>

            {/* FEATURES */}
            <section className="feature-area pt-50">
                <div className="container">
                    <div className="feature-wrapper">
                        <div className="single-feature-col">
                            <div className="single-feature">
                                <div className="feature-icon">
                                    <img
                                        src={CardPayments}
                                        alt="Free Shipping"
                                    />
                                </div>
                                <div className="feature-content">
                                    <h4 className="title">Free Shipping</h4>
                                    <span className="sub-title">
                                        Free Domestic Shipping on purchase
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="single-feature-col">
                            <div className="single-feature">
                                <div className="feature-icon">
                                    <img
                                        src={FreeShipping}
                                        alt="Card Payments"
                                    />
                                </div>
                                <div className="feature-content">
                                    <h4 className="title">Secure Payments</h4>
                                    <span className="sub-title">
                                        Your payment information is processed
                                        securely.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="single-feature-col">
                            <div className="single-feature">
                                <div className="feature-icon">
                                    <img src={EasyReturns} alt="Easy Returns" />
                                </div>
                                <div className="feature-content">
                                    <h4 className="title">Customer Service</h4>
                                    <span className="sub-title">
                                        Responsive customer service via email,
                                        text or phone.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default PrivacyPolicy;
