import actions from './actions';

const initialState = {
  color: null,
  type: null,
  composition: null,
  pattern: null,
  params: null,
  selectedProductId: null,
  selectedProductColorId: null,
  selectedProduct: null,
  wishList: 0,
  search: null,
  cart: [],
  tag: null,
  modal: false,
  orderDetails: "",
  orderConfirmation: "",
  wishListData: {},
  wishListTrigger:false,
};

const {
  GET_COLOR,
  GET_TYPE,
  GET_COMPOSITION,
  GET_PATTERN,
  SET_PARAMS,
  SET_SELECTED_PRODUCT_ID,
  SET_SELECTED_PRODUCT,
  SET_WISHLIST,
  SET_SEARCH,
  CART_PRODUCTS,
  SET_TAG,
  SET_MODAL,
  LOGOUT,
  SET_ORDER_DETAILS,
  SET_ORDER_CONFIRMATION,
  SET_WISHLIST_DATA,
  SET_WISHLIST_TRIGGER
} = actions;
const ProductReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_COLOR:
      return {
        ...state,
        color: data,
      };
    case GET_TYPE:
      return {
        ...state,
        type: data,
      };
    case GET_COMPOSITION:
      return {
        ...state,
        composition: data,
      };
    case GET_PATTERN:
      return {
        ...state,
        pattern: data,
      };
    case SET_PARAMS:
      return {
        ...state,
        params: data,
      };
    case SET_SELECTED_PRODUCT_ID:
      return {
        ...state,
        selectedProductId: data.selectedProductId,
        selectedProductColorId: data.selectedProductColorId,
      };
    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: data,
      };
    case SET_WISHLIST:
      return {
        ...state,
        wishList: data,
      };
    case SET_SEARCH:
      return {
        ...state,
        search: data,
      };
    case CART_PRODUCTS:
      return {
        ...state,
        cart: data,
      };
    case SET_TAG:
      return {
        ...state,
        tag: data,
      };
    case SET_MODAL:
      console.log("data--->", data);
      return {
        ...state,
        modal: data,
      };
    case SET_ORDER_DETAILS:
      console.log("data--->", data);
      return {
        ...state,
        orderDetails: data,
      };
    case SET_ORDER_CONFIRMATION:
      console.log("data--->", data);
      return {
        ...state,
        orderConfirmation: data,
      };
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case SET_WISHLIST_DATA:
      console.log("data--->", data);
      return {
        ...state,
        wishListData: data,
      };
      case SET_WISHLIST_TRIGGER:
      console.log("SET_WISHLIST_TRIGGER--->", data);
      return {
        ...state,
        wishListTrigger: data,
      };
    default:
      return state;
  }
};

export default ProductReducer;
