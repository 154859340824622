/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Nav, Navbar, NavDropdown, Offcanvas, Button } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import colors from "../data/colors";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getColor,
  getPattern,
  getType,
  getComposition,
  getWishList,
  cartList,
  getUserProfie,
} from "../service/api";
import Modal from "react-bootstrap/Modal";

import actions from "../redux/products/actions";
import userActions from "../redux/user/actions";
/* IMAGES IMPORT */
import InspiroworldLogo from "../assets/logos/logo.png";

import "react-bootstrap-submenu/dist/index.css";
import { useUpdateCart } from "../hooks/useUpdateCart";
import { MdCancel } from "react-icons/md";
import { TbCoin } from "react-icons/tb";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user);
  const [userData, setUserData] = useState();
  const [color, setColor] = useState([]);
  const [pattern, setPattern] = useState([]);
  const [type, setType] = useState([]);
  const [composition, setComposition] = useState([]);
  const [wishListNo, setwishListNo] = useState(0);
  const { setUpdateCart } = useUpdateCart();

  useEffect(() => {
    if (
      color?.length === 0 ||
      pattern?.length === 0 ||
      type?.length === 0 ||
      composition?.length === 0
    ) {
      getColor().then((res) => {
        setColor(res?.data);
        dispatch(actions.setColor(res?.data));
      });
      getPattern().then((res) => {
        setPattern(res?.data);
        dispatch(actions.setPattern(res?.data));
      });
      getType().then((res) => {
        setType(res?.data);
        dispatch(actions.setType(res?.data));
      });
      getComposition().then((res) => {
        setComposition(res?.data);
        dispatch(actions.setComposition(res?.data));
      });
    }

    setUpdateCart(true);
  }, []);

  useEffect(() => {
    getUserProfie().then((res) => {
      setUserData(res?.data);
      console.log(res?.data);
    });
  }, []);

  const locationArr = ["/", "/my-account", "/wishlist", "/register"];
  useEffect(() => {
    console.log("location", location?.pathname);
    if (locationArr.includes(location?.pathname)) {
      if (user?.token) {
        getWishList({
          page: 0,
          sizePerPage: 10,
        }).then((res) => {
          dispatch(actions.setWishList(res?.data?.totalDocs));
          setwishListNo(res?.data?.totalDocs);
        });
      } else {
        dispatch(actions.setWishList(0));
        setwishListNo(0);
      }
    }
  }, [location?.pathname, user?.token]);

  const [showSearch, setShowSearch] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const searchProduct = (e) => {
    setSearchValue(e.target.value);
  };

  const searchFabric = () => {
    if (searchValue) {
      dispatch(
        actions.setSearch(
          searchValue.charAt(0).toUpperCase() +
            searchValue.slice(1).toLowerCase()
        )
      );
      navigate("/products");
      setTimeout(() => {
        setShowSearch(!showSearch);
      }, 800);
      // setSearchValue('')
    } else if (!searchValue && location?.pathname === "/products") {
      dispatch(
        actions.setSearch(
          searchValue.charAt(0).toUpperCase() +
            searchValue.slice(1).toLowerCase()
        )
      );
      setTimeout(() => {
        setShowSearch(!showSearch);
      }, 800);
      // setSearchValue("");
    }
  };
  // useEffect(() => {
  //   if (searchValue === "") {
  //     dispatch(actions.setSearch(searchValue))
  //   }
  //
  // }, [searchValue])

  const products = useSelector((state) => state.products);

  useEffect(() => {
    if (products.wishList === undefined) {
      setwishListNo(0);
      return;
    }

    setwishListNo(products.wishList);
  }, [products.wishList]);

  const hideSearch = () => {
    setSearchValue("");
    setShowSearch(false);
    dispatch(actions.setSearch(null));
  };
  return (
    <header className="header-section">
      <div className="header-main sticky-nav">
        {/* TOP HEADER */}
        {/* <div className="top-header">
          <h5>FREE DOMESTIC SHIPPING ON PURCHASE OF $75+</h5>
        </div> */}

        <div className="container">
          <div className="header-wrap">
            {/* INSPIROWORLD LOGO */}
            <div
              className="logo"
              onClick={() => {
                navigate("/");
                setSearchValue("");
                setShowSearch(false);
                dispatch(actions.setSearch(null));
              }}
            >
              <img src={InspiroworldLogo} alt="Inspiroworld" />
            </div>

            {/* HEADER RIGHT */}
            <div className="header-right order-md-2">
              {/* SEARCH ICON */}
              {/* <div
                className="searchbar"
                onClick={() => {
                  // setSearchValue("");
                  // dispatch(actions.setSearch(""));
                  setShowSearch(!showSearch);
                }}
              >
                <div className="header-actions">
                  <svg
                    stroke="#000"
                    fill="#000"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1.7em"
                    width="1.7em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path>
                  </svg>
                </div>
              </div> */}

              {/* WISHLIST ICON */}
              <Link
                to={user?.token ? "/wishlist" : "/register"}
                onClick={hideSearch}
              >
                <div className="header-actions ">
                  <div className="cart-action-icon">
                    <svg
                      stroke="#424242"
                      fill="#424242"
                      stroke-width="0"
                      viewBox="0 0 1024 1024"
                      height="1.7em"
                      width="1.7em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M923 283.6a260.04 260.04 0 0 0-56.9-82.8 264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z"></path>
                    </svg>
                    <span className="pro-count">
                      {wishListNo && wishListNo > 0 ? wishListNo : 0}
                    </span>
                  </div>
                </div>
              </Link>

              {/* COIN ICON */}

              {/* <Link>
                <div className="header-actions pl-4">
           }
                  <div className="cart-action-icon">
                    <TbCoin
                      style={{
                        width: "28px",
                        height: "28px",
                        color: "#424242",
                      }}
                      
                    >
                        </TbCoin>
                    <span className="pro-count">
                      {userData?.points ? userData?.points : 0}
                    </span>
                  </div>
                </div>
              </Link> */}

              {/* ACCOUNT ICON */}
              {user?.token ? (
                <Link to="/my-account" onClick={hideSearch}>
                  <div className="header-actions pl-4">
                    <div className="account-action-icon">
                      <svg
                        stroke="#424242"
                        fill="#424242"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        height="1.7em"
                        width="1.7em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 2A10.13 10.13 0 0 0 2 12a10 10 0 0 0 4 7.92V20h.1a9.7 9.7 0 0 0 11.8 0h.1v-.08A10 10 0 0 0 22 12 10.13 10.13 0 0 0 12 2zM8.07 18.93A3 3 0 0 1 11 16.57h2a3 3 0 0 1 2.93 2.36 7.75 7.75 0 0 1-7.86 0zm9.54-1.29A5 5 0 0 0 13 14.57h-2a5 5 0 0 0-4.61 3.07A8 8 0 0 1 4 12a8.1 8.1 0 0 1 8-8 8.1 8.1 0 0 1 8 8 8 8 0 0 1-2.39 5.64z"></path>
                        <path d="M12 6a3.91 3.91 0 0 0-4 4 3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4zm0 6a1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2 1.91 1.91 0 0 1-2 2z"></path>
                      </svg>
                    </div>
                  </div>
                </Link>
              ) : (
                <Link to="/register" onClick={hideSearch}>
                  <div className="header-actions pl-4">
                    <div className="account-action-icon">
                      <svg
                        stroke="#424242"
                        fill="#424242"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        height="1.7em"
                        width="1.7em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 2A10.13 10.13 0 0 0 2 12a10 10 0 0 0 4 7.92V20h.1a9.7 9.7 0 0 0 11.8 0h.1v-.08A10 10 0 0 0 22 12 10.13 10.13 0 0 0 12 2zM8.07 18.93A3 3 0 0 1 11 16.57h2a3 3 0 0 1 2.93 2.36 7.75 7.75 0 0 1-7.86 0zm9.54-1.29A5 5 0 0 0 13 14.57h-2a5 5 0 0 0-4.61 3.07A8 8 0 0 1 4 12a8.1 8.1 0 0 1 8-8 8.1 8.1 0 0 1 8 8 8 8 0 0 1-2.39 5.64z"></path>
                        <path d="M12 6a3.91 3.91 0 0 0-4 4 3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4zm0 6a1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2 1.91 1.91 0 0 1-2 2z"></path>
                      </svg>
                    </div>
                  </div>
                </Link>
              )}

              {/* CART ICON */}
              <Link to="/cart" onClick={hideSearch}>
                <div className="header-actions pl-4">
                  {/* CART ICON */}
                  <div className="cart-action-icon">
                    <svg
                      stroke="#424242"
                      fill="#424242"
                      stroke-width="0"
                      viewBox="0 0 1024 1024"
                      height="1.7em"
                      width="1.7em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M922.9 701.9H327.4l29.9-60.9 496.8-.9c16.8 0 31.2-12 34.2-28.6l68.8-385.1c1.8-10.1-.9-20.5-7.5-28.4a34.99 34.99 0 0 0-26.6-12.5l-632-2.1-5.4-25.4c-3.4-16.2-18-28-34.6-28H96.5a35.3 35.3 0 1 0 0 70.6h125.9L246 312.8l58.1 281.3-74.8 122.1a34.96 34.96 0 0 0-3 36.8c6 11.9 18.1 19.4 31.5 19.4h62.8a102.43 102.43 0 0 0-20.6 61.7c0 56.6 46 102.6 102.6 102.6s102.6-46 102.6-102.6c0-22.3-7.4-44-20.6-61.7h161.1a102.43 102.43 0 0 0-20.6 61.7c0 56.6 46 102.6 102.6 102.6s102.6-46 102.6-102.6c0-22.3-7.4-44-20.6-61.7H923c19.4 0 35.3-15.8 35.3-35.3a35.42 35.42 0 0 0-35.4-35.2zM305.7 253l575.8 1.9-56.4 315.8-452.3.8L305.7 253zm96.9 612.7c-17.4 0-31.6-14.2-31.6-31.6 0-17.4 14.2-31.6 31.6-31.6s31.6 14.2 31.6 31.6a31.6 31.6 0 0 1-31.6 31.6zm325.1 0c-17.4 0-31.6-14.2-31.6-31.6 0-17.4 14.2-31.6 31.6-31.6s31.6 14.2 31.6 31.6a31.6 31.6 0 0 1-31.6 31.6z"></path>
                    </svg>
                    <span className="pro-count">
                      {products?.cart?.length && products?.cart?.length > 0
                        ? products?.cart?.length
                        : 0}
                    </span>
                  </div>
                </div>
              </Link>
            </div>

            {/* NAVIGATION OPTIONS */}
            <div className="header-nav order-md-1">
              <Navbar expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Offcanvas id="offcanvas-expand-lg" placement="end">
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title id="offcanvas-expand-lg">
                      <div className="logo">
                        <img
                          src={InspiroworldLogo}
                          width={70}
                          alt="Inspiroworld"
                        />
                      </div>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="main-menu">
                      <Nav.Link
                        className="text-black"
                        onClick={() => {
                          navigate(`/`);
                          setSearchValue("");
                          setShowSearch(false);
                          dispatch(actions.setSearch(null));
                        }}
                      >
                        Home
                      </Nav.Link>

                      <Nav.Link
                        className="text-black"
                        onClick={() => {
                          navigate(`/products`);
                        }}
                      >
                        Shop
                      </Nav.Link>

                      {/* SHOP FABRICS */}
                      {/* <NavDropdownMenu
                                                title="Shop"
                                                className="sub-menu"
                                                alignRight
                                            >
                                                <DropdownSubmenu
                                                    className="sub-sub-menu"
                                                    href="/#"
                                                    title="Shop By Type"
                                                >
                                                    {type
                                                        ?.sort(function (a, b) {
                                                            if (
                                                                a.name.toLowerCase() <
                                                                b.name.toLowerCase()
                                                            )
                                                                return -1;
                                                            if (
                                                                a.name.toLowerCase() >
                                                                b.name.toLowerCase()
                                                            )
                                                                return 1;
                                                            return 0;
                                                        })
                                                        .map((data) => {
                                                            return (
                                                                <NavDropdown.Item
                                                                    href={`/products/category=${data?._id}+${data?.name}`}
                                                                    onClick={() => {
                                                                        setTimeout(
                                                                            () => {
                                                                                dispatch(
                                                                                    actions.setParams(
                                                                                        {
                                                                                            category:
                                                                                                data,
                                                                                        },
                                                                                    ),
                                                                                );
                                                                                dispatch(
                                                                                    actions.setTag(
                                                                                        null,
                                                                                    ),
                                                                                );
                                                                            },
                                                                            1500,
                                                                        );
                                                                        navigate(
                                                                            `/products/category=${data?._id}+${data?.name}`,
                                                                        );
                                                                    }}
                                                                >
                                                                    {data?.name}
                                                                </NavDropdown.Item>
                                                            );
                                                        })}
                                                </DropdownSubmenu>
                                            </NavDropdownMenu> */}

                      {/* SALE */}
                      {/* <Nav.Link
                                                className="text-black"
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        dispatch(
                                                            actions.setTag({
                                                                id: "63a2cdf57f1c50c4a020c2c7",
                                                                name: "Sale",
                                                            }),
                                                        );
                                                        dispatch(
                                                            actions.setParams({
                                                                id: "63a2cdf57f1c50c4a020c2c7",
                                                                name: "Sale",
                                                            }),
                                                        );
                                                    }, 1500);
                                                    navigate(
                                                        `/products/tag=63a2cdf57f1c50c4a020c2c7+Sale`,
                                                    );
                                                }}
                                            >
                                                Deal and Steal
                                            </Nav.Link> */}

                      {/* WHOLESALE */}
                      <Nav.Link
                        className="text-black"
                        onClick={() => {
                          navigate(`/wholesale`);
                          setSearchValue("");
                          setShowSearch(false);
                          dispatch(actions.setSearch(null));
                        }}
                      >
                        Wholesale
                      </Nav.Link>
                      {/* <Nav.Link
                        className="text-black"
                        onClick={() => {
                          navigate(`/`)
                          setSearchValue("")
                          setShowSearch(false)
                          dispatch(actions.setSearch(null))
                        }}>
                        Export
                      </Nav.Link> */}
                      {/* CONTACT US */}
                      <Nav.Link
                        className="text-black"
                        onClick={() => {
                          navigate(`/contact-us`);
                          setSearchValue("");
                          setShowSearch(false);
                          dispatch(actions.setSearch(null));
                        }}
                      >
                        Contact Us
                      </Nav.Link>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Navbar>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          showSearch
            ? "searchbar-below-header show"
            : "searchbar-below-header hide"
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-10 search-flex">
              <input
                value={searchValue}
                onChange={searchProduct}
                type="text"
                placeholder="Search Fabrics"
                className="form-control"
                style={{ textTransform: "capitalize" }}
              />
              {searchValue ? (
                <MdCancel
                  className="cross-btn"
                  onClick={() => {
                    dispatch(actions.setSearch(null));
                    setSearchValue("");
                    setShowSearch(false);
                  }}
                ></MdCancel>
              ) : null}
            </div>
            <div className="col-xl-2">
              <Button className="search-btn" onClick={searchFabric}>
                Search Fabric
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="wishlist-modal"
        show={products?.modal}
        onHide={() => {
          dispatch(actions.setModal(false));
        }}
      >
        <Modal.Header className="wishlist-modal-header">
          <Modal.Title className="wishlist-modal-title">
            Add to Wishlist
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="wishlist-modal-body">
          <p>Please sign in to your account to add this fabric to wishlist.</p>
          <p>
            If you don't have an account,{" "}
            <span
              onClick={() => {
                dispatch(actions.setModal(false));
                navigate("/register");
                setSearchValue("");
                setShowSearch(false);
                dispatch(actions.setSearch(null));
              }}
              style={{ color: "#3E3EF4", cursor: "pointer" }}
            >
              Click here
            </span>{" "}
            to create an account.
          </p>
        </Modal.Body>
        <Modal.Footer className="wishlist-modal-footer">
          <Button
            style={{ width: "80px", marginLeft: "10px" }}
            onClick={() => {
              dispatch(actions.setModal(false));
              navigate("/register");
              setSearchValue("");
              setShowSearch(false);
              dispatch(actions.setSearch(null));
            }}
          >
            Sign in
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              dispatch(actions.setModal(false));
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </header>
  );
}

export default Header;
