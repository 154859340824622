/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React,{useState} from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import * as yup from "yup";
import { postNewsLetter } from '../service/api';

function Rewards() {
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = yup.object().shape({
    email: yup.string().email().required()
  });

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)

  const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  const sendEmail = (e) =>{
    e.preventDefault()
    if(!email){
      setEmailError(true)
      return;
    }
    if(!filter.test(email)){
      setEmailError(true)
      return;
    }
    postNewsLetter({email}).then((res) => {
        enqueueSnackbar(res?.message, { variant: 'success' });
        if (res?.status) {
          setEmail('')
        }
      })
      .catch((e) => {
          console.log("error--->", e?.response);
          enqueueSnackbar(
            e?.response?.data?.message
              ? e?.response?.data?.message
              : "Something went wrong. Please try again.", { variant: 'error' }
          );
        });
  }

  // const formik = useFormik({
  //   initialValues: {
  //     email: "",
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: (values) => {
  //     postNewsLetter(values).then((res) => {
  //       enqueueSnackbar(res?.message);
  //       if (res?.status) {
  //         formik.setFieldValue("email", "");
  //       }
  //     })
  //       .catch((e) => {
  //         console.log("error--->", e);
  //         // enqueueSnackbar(e?.response?.data?.message);
  //       });
  //   },
  // });

  return (
    <div className="rewards">
      {/* BANNER */}
      <div className="rewards-banner">
        <div className="container">
          <div className="col-lg-6 col-sm-12">
            <div className="rewards-text">
              <h1>REWARDS</h1>
              <div className="d-flex mt-3" id="rewards-buttons">
                <Link to="/register">
                  <Button className="signup-btn">JOIN NOW</Button>
                </Link>
                <Link to="/register">
                  <Button className="login-btn">LOGIN</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* START EARNING TODAY */}
      <div className="how-it-works pt-50 pb-50">
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-xl-8 col-sm-12">
              <h2>START EARNING TODAY</h2>
              <div className="steps mt-5">
                <div className="row">
                  <div className="col-xl-4 col-sm-12">
                    <div className="steps-detail">
                      <h3>01</h3>
                      <h4>SIGN UP</h4>
                      <h5>Create your account.</h5>
                    </div>
                  </div>
                  <div className="col-xl-4 col-sm-12">
                    <div className="steps-detail">
                      <h3>02</h3>
                      <h4>EARN POINTS</h4>
                      <h5>
                        Earn points everytime you
                        <br />
                        make a purchase.
                      </h5>
                    </div>
                  </div>
                  <div className="col-xl-4 col-sm-12">
                    <div className="steps-detail">
                      <h3>03</h3>
                      <h4>GET DISCOUNTS</h4>
                      <h5>Exchange your points for discount coupons.</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* WAYS TO EARN POINTS */}
      <div className="ways-to-earn-points pt-50 pb-50">
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-xl-12 col-sm-12">
              <h2>
                Ways to earn <span>points</span>
              </h2>
              <div className="earn-points-section mt-5">
                <div className="benefits">
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-xl-4 col-sm-12">
                      <div className="benefit-card border-left-light">
                        <div className="system">
                          <img
                            src={require("../assets/icons/shopping-cart.png")}
                            width="70"
                            alt="Make a Purchase"
                          />
                          <div className="system-details">
                            <h5>5 Points Per Dollar</h5>
                            <p>Make a purchase</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-xl-4 col-sm-12">
                      <div className="benefit-card border-left-light">
                        <div className="system">
                          <img
                            src={require("../assets/icons/account.png")}
                            width="70"
                            alt="Make a Purchase"
                          />
                          <div className="system-details">
                            <h5>Create your account</h5>
                            <p>200 points</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="benefit-card border-left-light">
                        <div className="system">
                          <img
                            src={require("../assets/icons/facebook.png")}
                            width="70"
                            alt="Make a Purchase"
                          />
                          <div className="system-details">
                            <h5>Like us on Facebook</h5>
                            <p>25 points</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="benefit-card border-left-light">
                        <div className="system">
                          <img
                            src={require("../assets/icons/instagram.png")}
                            width="70"
                            alt="Make a Purchase"
                          />
                          <div className="system-details">
                            <h5>Follow us on Instagram</h5>
                            <p>25 points</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-xl-4 col-sm-12">
                      <div className="benefit-card border-left-light">
                        <div className="system">
                          <img
                            src={require("../assets/icons/share.png")}
                            width="70"
                            alt="Make a Purchase"
                          />
                          <div className="system-details">
                            <h5>Share on Facebook</h5>
                            <p>25 points</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="benefit-card border-left-light">
                        <div className="system">
                          <img
                            src={require("../assets/icons/review.png")}
                            width="70"
                            alt="Make a Purchase"
                          />
                          <div className="system-details">
                            <h5>Write a Review</h5>
                            <p>50 points</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="benefit-card border-left-light">
                        <div className="system">
                          <img
                            src={require("../assets/icons/yelp.png")}
                            width="70"
                            alt="Make a Purchase"
                          />
                          <div className="system-details">
                            <h5>Write a Review on Yelp</h5>
                            <p>50 points</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* HOW TO REDEEM YOUR DISCOUNT */}
      <div className="redeem-your-discount pt-50 pb-50">
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-xl-12 col-sm-12">
              <h2>HOW TO REDEEM YOUR POINTS</h2>
              <h5>
                Utilizing your points is simple. Simply convert your points into
                savings vouchers to be used in any order.
              </h5>
            </div>
          </div>
          <div className="row mt-4" style={{ justifyContent: "center" }}>
            <div className="col-xl-3 col-sm-12">
              <div className="benefit-card new-box">
                <div className="system">
                  <img
                    src={require("../assets/icons/dollar.png")}
                    width="70"
                    alt="Make a Purchase"
                  />
                  <div className="system-details">
                    <h5>$5 OFF DISCOUNT</h5>
                    <p>500 points</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-12">
              <div className="benefit-card new-box">
                <div className="system">
                  <img
                    src={require("../assets/icons/dollar.png")}
                    width="70"
                    alt="Make a Purchase"
                  />
                  <div className="system-details">
                    <h5>$10 OFF DISCOUNT</h5>
                    <p>1000 points</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-12">
              <div className="benefit-card new-box-last">
                <div className="system">
                  <img
                    src={require("../assets/icons/dollar.png")}
                    width="70"
                    alt="Make a Purchase"
                  />
                  <div className="system-details">
                    <h5>$25 OFF DISCOUNT</h5>
                    <p>2500 points</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* EMAIL NEWSLETTER */}
      <div className="email-newsletter pt-50 pb-50" id="newsletter">
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-xl-12 col-sm-12">
              <h2>there's some more!</h2>
              <p>
                Sign up for newsletter and get a one time mystery gift points.
              </p>
            </div>
            <div className="col-xl-7 col-sm-12">
              <div className="row mt-4">
                <form>
                  <div className="row">
                    <div className="col-xl-10 col-sm-12">
                      <input
                        value={email}
                        onChange={(e) => {
                          setEmailError(false);
                          setEmail(e.target.value);
                        }}
                        id="email"
                        type="email"
                        className="form-control-big"
                        placeholder="Email"
                      />
                      <small
                        className="validation-error"
                        style={{ color: "red" }}
                      >
                        {emailError ? "Enter valid email" : ""}
                      </small>
                    </div>
                    <div className="col-xl-2 col-sm-12" id='mobile-padd' style={{ padding: '0px' }}>
                      <Button
                        className="submit-btn-rewards"
                        type="submit"
                        onClick={sendEmail}
                      >
                        Subscribe
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rewards;