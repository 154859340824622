/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IMG_URL } from "../config";
import axios from "axios";
import { shipRocketTokenApi } from "../service/api";

function OrderDetails() {
  const { cart, orderDetails } = useSelector((state) => state?.products);
  const [total, setTotal] = useState(0);
  const [track_url, settrack_url] = useState("");
  console.log("%c Line:13 🥟 track_url", "color:#ed9ec7", track_url);

  useEffect(() => {
    let totalPrice = 0;
    cart?.map((obj) => {
      totalPrice = totalPrice + obj?.quantity * obj?.amount;
    });
    console.log("totalPrice: ", totalPrice);
    setTotal(totalPrice);
  }, [cart]);

  const [orderDetailsState, setorderDetailsState] = useState("");

  useEffect(() => {
    console.log("OrderDetails-->", orderDetails);
    setorderDetailsState(orderDetails);

    return () => {
      setorderDetailsState();
    };
  }, [orderDetails]);

  // const shipRocketToken =
  //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaXYyLnNoaXByb2NrZXQuaW4vdjEvZXh0ZXJuYWwvYXV0aC9sb2dpbiIsImlhdCI6MTcxMDY1MzkzNiwiZXhwIjoxNzExNTE3OTM2LCJuYmYiOjE3MTA2NTM5MzYsImp0aSI6IlBSM1ZWZFBQZ0pmZjhBQm0iLCJzdWIiOjQ1Mzg3NzMsInBydiI6IjA1YmI2NjBmNjdjYWM3NDVmN2IzZGExZWVmMTk3MTk1YTIxMWU2ZDkiLCJjaWQiOjQzNzIwMTB9.U8X4_STHZ4BemqyvEtvkRkZXDmlOJRTxfh5g_Qon4mA";

  useEffect(() => {
    (async () => {
      try {
            const data = await shipRocketTokenApi()
            // console.log("%c Line:51 🍞 data", "color:#ea7e5c", data.data)
            ;

        let res = await axios.get(
          `https://apiv2.shiprocket.in/v1/external/courier/track/shipment/${orderDetails?.shipRocketOrderId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${data.data}`,
            },
          }
        );
        console.log("%c Line:51 🍞 res", "color:#ea7e5c", res);
        if (res.data) {
          settrack_url(res?.data?.tracking_data?.track_url);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <div className="order-details pt-50 pb-50">
      <div className="container">
        <div className="row">
          {/* USER DETAILS */}
          <div className="col-lg-12 col-sm-12">
            <div className="order-details-review">
              <div className="row">
                {orderDetails?.shippingType === "Pickup" ? (
                  <div className="col-xl-4 col-sm-12">
                    <div className="address-box">
                      <h5>
                        <svg
                          stroke="#000"
                          fill="#000"
                          stroke-width="0"
                          viewBox="0 0 24 24"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="none"
                            stroke="#000"
                            stroke-width="2"
                            d="M12,22 C12,22 4,16 4,10 C4,5 8,2 12,2 C16,2 20,5 20,10 C20,16 12,22 12,22 Z M12,13 C13.657,13 15,11.657 15,10 C15,8.343 13.657,7 12,7 C10.343,7 9,8.343 9,10 C9,11.657 10.343,13 12,13 L12,13 Z"
                          ></path>
                        </svg>
                        Shipping Type
                      </h5>
                      <div className="address-details">
                        <p>Pickup</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="col-xl-4 col-sm-12">
                      <div className="address-box">
                        <h5>
                          <svg
                            stroke="#000"
                            fill="#000"
                            stroke-width="0"
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill="none"
                              stroke="#000"
                              stroke-width="2"
                              d="M12,22 C12,22 4,16 4,10 C4,5 8,2 12,2 C16,2 20,5 20,10 C20,16 12,22 12,22 Z M12,13 C13.657,13 15,11.657 15,10 C15,8.343 13.657,7 12,7 C10.343,7 9,8.343 9,10 C9,11.657 10.343,13 12,13 L12,13 Z"
                            ></path>
                          </svg>
                          Shipping Address
                        </h5>
                        <div className="address-details">
                          <p>
                            {orderDetailsState?.personalData?.firstName}{" "}
                            {orderDetailsState?.personalData?.lastName}
                          </p>
                          <p>{orderDetailsState?.personalData?.company}</p>
                          <div className="address">
                            <p>{orderDetailsState?.shippingAddress?.address}</p>
                            <p>
                              {orderDetailsState?.shippingAddress?.address1}
                            </p>
                            <p>
                              {orderDetailsState?.shippingAddress?.city} -{" "}
                              {orderDetailsState?.shippingAddress?.zip}
                            </p>
                            <p>{orderDetailsState?.shippingAddress?.country}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="address-box">
                        <h5>
                          <svg
                            stroke="#000"
                            fill="#000"
                            stroke-width="0"
                            style={{
                              marginRight: "10px",
                            }}
                            viewBox="0 0 16 16"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z"></path>
                            <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z"></path>
                          </svg>
                          Billing Address
                        </h5>
                        <div className="address-details">
                          <p>
                            {orderDetailsState?.personalData?.firstName}{" "}
                            {orderDetailsState?.personalData?.lastName}
                          </p>
                          <p>{orderDetailsState?.personalData?.company}</p>
                          <div className="address">
                            <p>{orderDetailsState?.shippingAddress?.address}</p>
                            <p>
                              {orderDetailsState?.shippingAddress?.address1}
                            </p>
                            <p>
                              {orderDetailsState?.shippingAddress?.city} -{" "}
                              {orderDetailsState?.shippingAddress?.zip}
                            </p>
                            <p>{orderDetailsState?.shippingAddress?.country}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="address-box h-100">
                        <h5>
                          <svg
                            stroke="#000"
                            fill="#000"
                            stroke-width="0"
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g>
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M9 4h5.446a1 1 0 0 1 .848.47L18.75 10h4.408a.5.5 0 0 1 .439.74L19.637 18H19a6.01 6.01 0 0 1-1.535-.198L20.63 12H3.4l1.048 5.824A6.013 6.013 0 0 1 3 18h-.545l-1.24-6.821A1 1 0 0 1 2.197 10H3V5a1 1 0 0 1 1-1h1V1h4v3zm-4 6h11.392l-2.5-4H5v4zM3 20a5.978 5.978 0 0 0 4-1.528A5.978 5.978 0 0 0 11 20a5.978 5.978 0 0 0 4-1.528A5.978 5.978 0 0 0 19 20h2v2h-2a7.963 7.963 0 0 1-4-1.07A7.963 7.963 0 0 1 11 22a7.963 7.963 0 0 1-4-1.07A7.963 7.963 0 0 1 3 22H1v-2h2z"></path>
                            </g>
                          </svg>
                          Shipping Method
                        </h5>
                        <div className="address-details">
                          <p>
                            <b>Preferred Method</b>
                          </p>
                          <p>{orderDetailsState?.shippingData?.serviceName}</p>

                          <div className="address">
                            <p>Standard Delivery</p>
                            <p>(Normally 3-4 business days)</p>

                            <p title="tracking url">
                              <b>track url</b>🚚🚚
                              <a  target="_blank" href={track_url}>{track_url}</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* ORDER SUMMARY */}
          <div className="col-lg-12 col-sm-12">
            <div className="row pt-30">
              <div className="col-lg-9 col-sm-12">
                <div className="order-review">
                  <div className="table-responsive text-center">
                    <table className="table">
                      <thead>
                        <tr>
                          <th colSpan="2">Product</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetailsState &&
                          orderDetailsState?.order?.length > 0 &&
                          orderDetailsState?.order?.map((product) => {
                            return (
                              <>
                                <tr>
                                  <td className="image product-thumbnail">
                                    <img
                                      src={
                                        IMG_URL +
                                        product.product?.productImage[0]
                                      }
                                      width={80}
                                    />
                                  </td>
                                  <td>
                                    <h5>{product?.product?.name}</h5>
                                    <p>Quantity: {product?.quantity}</p>
                                  </td>
                                  <td>
                                    <p>
                                      ₹
                                      {(
                                        product?.quantity * product?.amount
                                      )?.toFixed(2)}
                                    </p>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="price-summary">
                  <h5>Price Details</h5>
                  <div className="price-details-row">
                    <p>Order Total</p>
                    <p>
                      ₹
                      {orderDetailsState?.shippingData
                        ? (
                            orderDetailsState?.payAmount?.toFixed(2) -
                            orderDetailsState?.shippingData?.shipmentCost?.toFixed(
                              2
                            ) -
                            (orderDetailsState?.taxAmount
                              ? orderDetailsState?.taxAmount?.toFixed(2)
                              : 0)
                          )?.toFixed(2)
                        : orderDetailsState?.amount?.toFixed(2)}
                    </p>
                  </div>
                  <div className="price-details-row mb-20">
                    {orderDetailsState?.shippingData ? (
                      <>
                        <p>Shipping Charge</p>
                        <p>
                          ₹
                          {orderDetailsState?.shippingData?.shipmentCost?.toFixed(
                            2
                          )}
                        </p>
                      </>
                    ) : null}
                  </div>

                  <div className="price-details-row mb-20">
                    {orderDetailsState?.taxAmount ? (
                      <>
                        <p>Tax amount</p>
                        <p>₹{orderDetailsState?.taxAmount?.toFixed(2)}</p>
                      </>
                    ) : null}
                  </div>

                  <div className="price-total-row">
                    <p>Total</p>

                    <p>₹{orderDetailsState?.payAmount?.toFixed(2)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ORDER SUMMARY */}
          {/* <div className="col-lg-12 col-sm-12">
              <div className="order-review pt-30">
                <div className="table-responsive text-center">
                  <table className="table">
                    <thead>
                      <tr>
                        <th colSpan="2">Product</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart &&
                        cart?.length > 0 &&
                        cart?.map((product) => {
                          return (
                            <>
                              <tr>
                                <td className="image product-thumbnail">
                                  <img
                                    src={
                                      IMG_URL +
                                      product.product?.productImage?.filter(
                                        (data) =>
                                          data?.color === product?.color?._id
                                      )[0]?.front[0]
                                    }
                                    alt={product?.product?.name}
                                    width={80}
                                  />
                                </td>
                                <td>
                                  <h5>{product?.product?.name}</h5>
                                  <p>Yards: {product?.quantity}</p>
                                </td>
                                <td>
                                  <p>
                                    $
                                    {(
                                      product?.quantity * product?.amount
                                    ).toFixed(2)}
                                  </p>
                                </td>
                              </tr>
                            </>
                          );
                        })}

                      <tr>
                        <td>
                          <p className="font-weight-700">Subtotal</p>
                        </td>
                        <td colSpan="3">
                          <p>${total}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="font-weight-700">Shipping</p>
                        </td>
                        <td colSpan="3">
                          <p>$0.00</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="font-weight-700">Offer Applied</p>
                        </td>
                        <td colSpan="3">
                          <p>No offer applied</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="font-weight-700">Estimated Tax</p>
                        </td>
                        <td colSpan="3">
                          <p>$0.00</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="font-weight-700 final-text">Total</p>
                        </td>
                        <td colSpan="3">
                          <p className="font-weight-700 final-text">${total}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> */}
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
