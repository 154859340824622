/* eslint-disable no-unused-vars */
import React, {useState} from "react"
import {Link} from "react-router-dom"
import colors from "../data/colors"
import fabricType from "../data/fabric-filter"
import pattern from "../data/pattern"
import content from "../data/content"
import fabrics from "../data/fabrics"

import {Button} from "react-bootstrap"

import ProductBox from "../components/ProductBox"

function FabricForSale() {
  return (
    <div className="fabric-page">
      {/* PRODUCTS */}
      <div className="shop-fabrics pt-50">
        <div className="container">
          <div className="row d-flex justify-content-between">
            {/* FILTERS */}
            <div className="col-lg-3 col-sm-12 filters">
              <div className="sidebar-widget">
                <h4 className="sidebar-title">Color</h4>
                <div className="sidebar-category">
                  <ul>
                    {colors.map((color) => {
                      return (
                        <li
                          className={"category-list " + color.selected}
                          key={color.id}>
                          <div className="d-flex align-items-center">
                            <div
                              className="color-category"
                              style={{backgroundColor: color.colorCode}}
                            />
                            <p className="color-name">{color.color}</p>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
              <div className="sidebar-widget">
                <h4 className="sidebar-title">Fabric Type</h4>
                <div className="sidebar-category">
                  <ul>
                    {fabricType.map((fabric) => {
                      return (
                        <li className="category-list" key={fabric.id}>
                          <div className="d-flex align-items-center">
                            <p className="color-name ml-0">
                              {fabric.fabricType}
                            </p>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
              <div className="sidebar-widget">
                <h4 className="sidebar-title">Pattern</h4>
                <div className="sidebar-category">
                  <ul>
                    {pattern.map((pattern) => {
                      return (
                        <li className="category-list" key={pattern.id}>
                          <div className="d-flex align-items-center">
                            <p className="color-name ml-0">
                              {pattern.patternName}
                            </p>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
              <div className="sidebar-widget">
                <h4 className="sidebar-title">Composition</h4>
                <div className="sidebar-category">
                  <ul>
                    {content.map((content) => {
                      return (
                        <li className="category-list" key={content.id}>
                          <div className="d-flex align-items-center">
                            <p className="color-name ml-0">{content.content}</p>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>

            {/* PRODUCTS LIST */}
            <div className="col-lg-9 col-sm-12 products-list">
              <div className="row">
                <div className="selected-filters mb-3">
                  <p className="mb-0">Filters:</p>
                  <div className="filters-list">
                    <div className="filter-box">
                      <p>Black</p>
                      <Button className="close-button">
                        <svg
                          stroke="#000"
                          fill="#000"
                          stroke-width="0"
                          viewBox="0 0 15 15"
                          height="0.7em"
                          width="0.7em"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clip-rule="evenodd"
                            d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                            fill="#000"></path>
                        </svg>
                      </Button>
                    </div>
                    <div className="filter-box">
                      <p>Silver</p>
                      <Button className="close-button">
                        <svg
                          stroke="#000"
                          fill="#000"
                          stroke-width="0"
                          viewBox="0 0 15 15"
                          height="0.7em"
                          width="0.7em"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clip-rule="evenodd"
                            d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                            fill="#000"></path>
                        </svg>
                      </Button>
                    </div>
                  </div>
                </div>
                {fabrics.map((fabric) => {
                  return (
                    <div className="col-lg-3 col-sm-12 mb-25">
                      <Link
                        to="/product"
                        style={{textDecoration: "none", color: "#000"}}>
                        <ProductBox
                          key={fabric.id}
                          img={fabric.fabricImg}
                          name={fabric.fabricName}
                          price={fabric.fabricPrice}
                        />
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FabricForSale
