const colors = [
  {
    id: 1,
    color: "Black",
    colorCode: "#000000",
    selected: "active",
  },
  {
    id: 2,
    color: "Silver",
    colorCode: "#949494",
    selected: "active",
  },
  {
    id: 3,
    color: "Pink Dusk",
    colorCode: "#A99287",
  },
  {
    id: 4,
    color: "Bronze",
    colorCode: "#B0703E",
  },
  {
    id: 5,
    color: "Khaki",
    colorCode: "#937350",
  },
  {
    id: 6,
    color: "Red",
    colorCode: "#DA0B21",
  },
  {
    id: 7,
    color: "Maroon",
    colorCode: "#C75F5E",
  },
  {
    id: 8,
    color: "Navy Blue",
    colorCode: "#132644",
  },
  {
    id: 9,
    color: "Gold",
    colorCode: "#B57D26",
  },
  {
    id: 10,
    color: "Turqoise",
    colorCode: "#065b97",
  },
  {
    id: 11,
    color: "Royal Blue",
    colorCode: "#020E68",
  },
  {
    id: 12,
    color: "Lavender",
    colorCode: "#AB89B0",
  },
  {
    id: 13,
    color: "Burgundy",
    colorCode: "#560412",
  },
  {
    id: 14,
    color: "Green",
    colorCode: "#007d68",
  },
  {
    id: 15,
    color: "Hunter Green",
    colorCode: "#005F4A",
  },
  {
    id: 16,
    color: "Dark Sky Blue",
    colorCode: "#97a3b8",
  },
  {
    id: 17,
    color: "White",
    colorCode: "#c1b6b5",
  },
  {
    id: 18,
    color: "Off-white",
    colorCode: "#cdc5c5",
  },
  {
    id: 19,
    color: "Peach",
    colorCode: "#ffbbae",
  },
  {
    id: 20,
    color: "Light Pink",
    colorCode: "#c9909f",
  },
  {
    id: 21,
    color: "Mauve",
    colorCode: "#964e59",
  },
  {
    id: 22,
    color: "Fuchsia",
    colorCode: "#8d0040",
  },
  {
    id: 23,
    color: "Magenta",
    colorCode: "#5b003c",
  },
  {
    id: 24,
    color: "Taupe",
    colorCode: "#a98d84",
  },
  {
    id: 25,
    color: "Champagne",
    colorCode: "#a58c6e",
  },
  {
    id: 26,
    color: "Olive",
    colorCode: "#a68d37",
  },
  {
    id: 27,
    color: "Teal",
    colorCode: "#76a3b0",
  },
];

export default colors;