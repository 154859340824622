import React from 'react'
import { Spinner } from "react-bootstrap";

function SpinnerComponent() {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
    }}>
    <Spinner />
    </div >
  )
}

export default SpinnerComponent