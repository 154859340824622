/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  login, resendOtp,
  verifyOtp,
  loginVerifyEmail,
  forgotPassword
} from '../service/api';
import actions from '../redux/user/actions';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Spinner, Button } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function CheckoutOptions() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [loginInput, setLoginInput] = useState({ name: '', email: '', password: '' })

  const loginTabs = {
    LOGIN: "LOGIN",
    VERIFY: "VERIFY",
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    SEND_OTP: "SEND_OTP",
    SEND_PASSWORD: "SEND_PASSWORD"
  }

  const [loginActiveTab, setLoginActiveTab] = useState(loginTabs.LOGIN);

  const [inputLogin, setInputLogin] = useState({
    name: false,
    email: false,
    password: false
  })

  const [otp, setOtp] = useState(null);
  const [otpError, setOtpError] = useState(false);

  const onLogchange = (e) => {
    setLoginInput({ ...loginInput, [e.target.name]: e.target.value })
    setInputLogin({ ...inputLogin, [e.target.name]: false })
  }

  function emailValidation(body) {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(body) === false) {

      return false;
    }
    return true;
  }

  const [loading, setLoading] = useState(false)
  const loginForm = (e) => {
    e.preventDefault()
    if (!loginInput?.email) {
      // enqueueSnackbar("Please enter email");\
      setInputLogin({ ...inputLogin, email: true })
      return
    }
    if (!emailValidation(loginInput?.email)) {
      // enqueueSnackbar("Please enter valid email");
      setInputLogin({ ...inputLogin, email: true })
      return
    }
    if (!loginInput?.password) {
      // enqueueSnackbar("Please enter your password");
      setInputLogin({ ...inputLogin, password: true })
      return
    }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    submitForm.append("password", loginInput.password);
    setLoading(true)
    login(submitForm).then(res => {
      console.log("res--->", res)
      if (res?.status === false) {
        enqueueSnackbar(res?.message, { variant: 'error' });

      }
      else if (res?.status === true) {
        enqueueSnackbar(res?.message, { variant: 'success' });
        dispatch(actions.setLoggedIn(true))
        dispatch(actions.setToken(res?.data?.token))
        localStorage.setItem("authenticated", true)
        localStorage.setItem("token", res?.data?.token)

        if (!res?.data?.userData?.isEmailVerified) {
          loginVerifyEmail({ "email": loginInput.email }).then(res => {
            console.log("verify email--->", res)
          })
          setLoginActiveTab(loginTabs.VERIFY)
          return
        }
        else {
          navigate('/checkout')
        }
      }
    })
      .catch(e => {
        console.log("error--->", e)
        enqueueSnackbar(e?.response?.data?.message, { variant: 'error' });
      })
      .finally(res => {
        setLoading(false)
      })
  }

  const resendLoginOtpFun = (e) => {
    e.preventDefault();
    if (!loginInput?.email) {
      // enqueueSnackbar("Please enter email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!emailValidation(loginInput?.email)) {
      // enqueueSnackbar("Please enter valid email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    setLoading(true);
    resendOtp(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: 'error' });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: 'success' });
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: 'error' });
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  const sendOtp = (e) => {
    e.preventDefault();
    if (!otp) {
      // enqueueSnackbar("Please enter 6 digit otp");
      setOtpError(true);
      return;
    }
    const submitForm = new FormData();
    submitForm.append("code", otp);
    console.log("send otp");
    setLoading(true);
    verifyOtp(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: 'success' });
          navigate('/checkout')
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: 'error' });
      })
      .finally((res) => {
        setOtp("");
        setLoading(false);
      });
  };

  const forgotPasswordFun = (e) => {
    e.preventDefault();
    if (!loginInput?.email) {
      // enqueueSnackbar("Please enter email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!emailValidation(loginInput?.email)) {
      // enqueueSnackbar("Please enter valid email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!loginInput?.password) {
      // enqueueSnackbar("Please enter your password");
      setInputLogin({ ...inputLogin, password: true });
      return;
    }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    submitForm.append("password", loginInput.password);
    setLoading(true);
    forgotPassword(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: 'error' });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: 'success' });
          setLoginActiveTab(loginTabs.LOGIN);
          setLoginInput({ ...loginInput, name: "", email: "", password: "" });
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: 'error' });
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  const [otpMatch, setOtpMatch] = useState(null)
  const sendOtpToMail = (e) => {
    e.preventDefault()
    if (!loginInput?.email) {
      // enqueueSnackbar("Please enter email");
      setInputLogin({ ...inputLogin, email: true })
      return
    }
    if (!emailValidation(loginInput?.email)) {
      // enqueueSnackbar("Please enter valid email");
      setInputLogin({ ...inputLogin, email: true })
      return
    }
    // if (!loginInput?.password) {
    //   // enqueueSnackbar("Please enter your password");
    //   setInputLogin({ ...inputLogin, password: true })
    //   return
    // }


    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    // submitForm.append("password", loginInput.password);
    setLoading(true)
    loginVerifyEmail(submitForm).then(res => {
      console.log("res--->", res)
      if (res?.status === false) {
        enqueueSnackbar(res?.message, { variant: 'error' });
      }
      else if (res?.status === true) {
        enqueueSnackbar(res?.message, { variant: 'success' });
        setOtpMatch(res?.data)
        setLoginActiveTab(loginTabs.SEND_OTP)
        // setLoginInput({ ...loginInput, name: '', email: '', password: '' })
      }
    })
      .catch(e => {
        console.log("error--->", e)
        enqueueSnackbar(e?.response?.data?.message);
      })
      .finally(res => {
        setLoading(false)
      })
  }

  const verifyOtpToChangePassword = (e) => {
    e.preventDefault()
    if (!otp) {
      // enqueueSnackbar("Please enter 6 digit otp");
      setOtpError(true)
      return
    }
    if (otp === otpMatch) {
      setLoginActiveTab(loginTabs.SEND_PASSWORD)
      enqueueSnackbar("OTP verified successfully", { variant: 'success' });
    }
    else {
      enqueueSnackbar("The OTP enetered is incorrect", { variant: 'error' });
    }
  }

  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
 
  return (
    <div className="checkout-options">
      <section className="pt-50 pb-50">
        <div className="container">
          <div className="row">
            {/* RETURNING CUSTOMER */}

            {/* {loading ?
              <div className="col-lg-6 col-sm-12" style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}>
                <Spinner />
              </div>
              : 
              <div className="col-lg-6 col-sm-12">
                <div className="login_wrap">
                  <h3 className="mb-10">Returning Customer</h3>
                  <p className="mb-30">
                    Lovely, welcome back! Sign in for faster checkout.
                  </p>
                  <form className="login-form">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Your Email"
                        onChange={onLogchange}
                      />
                      <small
                        className="validation-error"
                        style={{ color: "red" }}
                      >
                        {inputLogin?.email
                          ? "Email is required"
                          : ""}
                      </small>
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        onChange={onLogchange}
                      />
                      <small
                        className="validation-error"
                        style={{ color: "red" }}
                      >
                        {inputLogin?.password
                          ? "Password is required"
                          : ""}
                      </small>
                    </div>
                    <button className="login-btn" onClick={loginForm}>Log in</button>
                  </form>
                </div>
              </div>
            } */}

            {loading ? (
              <div
                className="col-lg-6 col-sm-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner />
              </div>
            ) : loginActiveTab === loginTabs.LOGIN ? (
              <div className="col-lg-6 col-sm-12">
                <div className="login_wrap">
                  <p className="mb-30">
                    Lovely, welcome back! Sign in for faster checkout.
                  </p>
                  <form className="login-form">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        value={loginInput?.email}
                        onChange={onLogchange}
                        className="form-control"
                        placeholder="Email"
                      />
                      <small
                        className="validation-error"
                        style={{ color: "red" }}
                      >
                        {inputLogin?.email ? "Email is required" : ""}
                      </small>
                    </div>

                    <div className="form-group">
                      <input
                        value={loginInput?.password}
                        onChange={onLogchange}
                        type={showLoginPassword ? "text" : "password"}
                        name="password"
                        className="form-control"
                        placeholder="Password"
                      />
                      <Button
                        className="password-icon"
                        onClick={() => setShowLoginPassword(!showLoginPassword)}
                      >
                        {showLoginPassword ? (
                          <AiFillEyeInvisible />
                        ) : (
                          <AiFillEye />
                        )}
                      </Button>
                      <small
                        className="validation-error"
                        style={{ color: "red" }}
                      >
                        {inputLogin?.password ? "password is required" : ""}
                      </small>
                    </div>

                    {/* <div className="forgot-pass">
                      <p
                        onClick={() => {
                          setLoginActiveTab(loginTabs.FORGOT_PASSWORD);
                        }}
                      >
                        Forgot Password ?
                      </p>
                    </div> */}
                    <button className="login-btn" onClick={loginForm}>
                      Log in
                    </button>
                  </form>
                </div>
              </div>
            ) : loginActiveTab === loginTabs.FORGOT_PASSWORD ? (
              <div className="col-lg-6 col-sm-12">
                <div className="login_wrap">
                  <h3 className="mb-30">Enter Credentials</h3>
                  <form className="login-form">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        value={loginInput?.email}
                        onChange={onLogchange}
                        className="form-control"
                        placeholder="Your Email"
                      />
                      <small
                        className="validation-error"
                        style={{ color: "red" }}
                      >
                        {inputLogin?.email ? "Email is required" : ""}
                      </small>
                    </div>

                    {/* <div className="form-group">
                      <input
                        value={loginInput?.password}
                        onChange={onLogchange}
                        type={showForgotPassword ? "text" : "password"}
                        name="password"
                        className="form-control"
                        placeholder="Password"
                      />
                      <Button
                        className="password-icon"
                        onClick={() =>
                          setShowForgotPassword(!showForgotPassword)
                        }
                      >
                        <AiFillEye />
                      </Button>
                      <small
                        className="validation-error"
                        style={{ color: "red" }}
                      >
                        {inputLogin?.password
                          ? "password is required"
                          : ""}
                      </small>
                    </div> */}

                    <div className="forgot-pass">
                      <p
                        onClick={() => {
                          setLoginActiveTab(loginTabs.LOGIN);
                        }}
                      >
                        Sign in to your account
                      </p>
                    </div>
                    <button className="login-btn" onClick={sendOtpToMail}>
                      Send OTP
                    </button>
                  </form>
                </div>
              </div>
            ) : loginActiveTab === loginTabs.SEND_OTP ? (
              <div className="col-lg-6 col-sm-12">
                <div className="login_wrap">
                  <h3 className="mb-30">Verify Your Account</h3>
                  <form className="login-form">
                    <div className="form-group">
                      <input
                        type="number"
                        name="otp"
                        value={loginInput?.otp}
                        onChange={(e) => {
                          setOtp(e.target.value);
                        }}
                        className="form-control"
                        placeholder="Enter OTP"
                      />
                      <small
                        className="validation-error"
                        style={{ color: "red" }}
                      >
                        {!otp ? "Please enter OTP" : ""}
                      </small>
                    </div>

                    <span className="forgot-pass">
                      <p onClick={sendOtpToMail}>Resend OTP</p>
                    </span>

                    <button
                      className="login-btn"
                      onClick={verifyOtpToChangePassword}
                    >
                      Verify OTP
                    </button>
                  </form>
                </div>
              </div>
            ) : loginActiveTab === loginTabs.SEND_PASSWORD ? (
              <div className="col-lg-6 col-sm-12">
                <div className="login_wrap">
                  <h3 className="mb-30">Enter Credentials</h3>
                  <form className="login-form">
                    <div className="form-group">
                      <input
                        value={loginInput?.password}
                        onChange={onLogchange}
                        type={showForgotPassword ? "text" : "password"}
                        name="password"
                        className="form-control"
                        placeholder="Password"
                      />
                      <Button
                        className="password-icon"
                        onClick={() =>
                          setShowForgotPassword(!showForgotPassword)
                        }
                      >
                        {showForgotPassword ? (
                          <AiFillEyeInvisible />
                        ) : (
                          <AiFillEye />
                        )}
                      </Button>
                      <small
                        className="validation-error"
                        style={{ color: "red" }}
                      >
                        {inputLogin?.password ? "password is required" : ""}
                      </small>
                    </div>

                    <div className="forgot-pass">
                      <p
                        onClick={() => {
                          setLoginActiveTab(loginTabs.LOGIN);
                        }}
                      >
                        Sign in to your account
                      </p>
                    </div>
                    <button className="login-btn" onClick={forgotPasswordFun}>
                      Change password
                    </button>
                  </form>
                </div>
              </div>
            ) : (
              <div className="col-lg-6 col-sm-12">
                <div className="login_wrap">
                  <h3 className="mb-30">Verify Email</h3>

                  <form className="login-form">
                    <div className="form-group">
                      <input
                        value={otp}
                        onChange={(e) => {
                          setOtpError(false);
                          setOtp(e.target.value);
                        }}
                        type="number"
                        name="otp"
                        className="form-control"
                        placeholder="Enter 6 digit OTP"
                      />
                      <small
                        className="validation-error"
                        style={{ color: "red" }}
                      >
                        {otpError ? "Please enter OTP" : ""}
                      </small>
                      <span className="forgot-pass">
                        <p onClick={resendLoginOtpFun}>Resend OTP</p>
                      </span>
                    </div>
                    <button className="login-btn" onClick={sendOtp}>
                      Verify Email
                    </button>
                  </form>
                </div>
              </div>
            )}

            {/* GUEST CHECKOUT AND NEW CUSTOMER */}
            <div className="col-lg-6 col-sm-12">
              <div className="row">
                <div className="col-lg-12 col-sm-12 mb-30">
                  <div className="login_wrap">
                    <h3 className="mb-10">New Customer</h3>
                    <p className="mb-30">
                      Become a member today and get exclusive rewards!
                    </p>
                    <button
                      className="proceed-to-btn"
                      onClick={() => navigate("/register")}
                    >
                      Proceed to Checkout
                    </button>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <div className="login_wrap">
                    <h3 className="mb-10">Guest Checkout</h3>
                    <p className="mb-30">
                      Not ready to become a member just yet?
                    </p>
                    <button
                      className="proceed-to-btn"
                      onClick={() => {
                        dispatch(actions.setLoggedIn(false))
                        navigate("/checkout")
                      }}
                    >
                      Proceed to Checkout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CheckoutOptions;