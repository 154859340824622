import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./testimonial.css";
import { Chip } from "@mui/material";
import { FaStar } from "react-icons/fa";
import Ruchi from "../../assets/Reviwer/Ruchi.png";
import Rutvik from "../../assets/Reviwer/Rutvik.png";
import Ajit from "../../assets/Reviwer/Ajit.png";
import Bhumi from "../../assets/Reviwer/Bhumi.png";
import Ramani from "../../assets/Reviwer/Ramani.png";
import Prince from "../../assets/Reviwer/Prince.png";

const Testimonial = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const data = [
    {
      img: Ruchi,
      name: "Ruchi Vadi",
      desc: "I've been using these ultra comfort sanitary pads for a few months now and I'm absolutely thrilled with the results! Highly recommend !!!!...",
    },

    {
      img: Rutvik,
      name: "Rutvik Patel",
      desc: "Fabulous...! All products are suites for all skin types and Flexible in use. Amazing quality.",
    },
    {
      img: Ajit,
      name: "Ajit Gohil",
      desc: "Excellent performance in product and wonderful packaging and quality is very very Best ,👍👍👍👍",
    },
    {
      img: Bhumi,
      name: "Bhumi Pansuriya",
      desc: "Excellent work and removing makeup, cleansing your face, applying a spot treatment for any blemishes. so usd  full product in skin care.",
    },
    {
      img: Ramani,
      name: "Ramani Niraj",
      desc: "Good product 👍 and company is best of excellent 👍👌",
    },
    {
      img: Prince,
      name: "Prince Sorathiya",
      desc: "The quality is outstanding, and each product feels luxurious on the skin. Absolutely thrilled with the cosmetic products from Lieve. HIGHLY RECOMMENDED!!",
    },
  ];

  const Card = ({ obj }) => {
    return (
      <div className="main-container">
        <div className="customer-desc text-sm md:text-base">{obj?.desc}</div>
        <div className="customer mt-4">
          <img
            src={obj?.img}
            onError={(e) => {
              e.target.onerror = null;
            }}
            loading="lazy"
            alt=""
            className="w-12 h-12 md:w-16 md:h-16"
          />
          <div>
            <p className="text-sm md:text-base font-bold">{obj?.name}</p>
            <Chip
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "4px" }}>5.0</span>
                  <FaStar style={{ color: "white", fontSize: "12px" }} />
                </div>
              }
              style={{
                backgroundColor: "#66BB6A",
                color: "white",
                fontWeight: "bold",
                fontSize: "12px",
                height: "22px",
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="testimonial1 py-8 bg-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <h2 className="my-3 text-lg md:text-2xl">
              Check what our Customers are Saying
            </h2>
            <h6 className="subtitle font-weight-normal text-sm md:text-base">
              You can rely on our amazing features list, and also our customer
              services will be a great experience for you without a doubt.
            </h6>
          </div>
        </div>
        <div className="mt-4">
          {isMobile ? (
            <Carousel
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              autoPlay={false}
              interval={3000}
              transitionTime={500}
              stopOnHover={true}
              swipeable={true}
              dynamicHeight={false}
            >
              {data?.map((obj, index) => (
                <Card key={index} obj={obj} />
              ))}
            </Carousel>
          ) : (
            <Carousel
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              autoPlay={false}
              interval={3000}
              transitionTime={500}
              stopOnHover={true}
              swipeable={true}
              centerMode={!isMobile}
              centerSlidePercentage={isMobile ? 100 : 33.33}
              dynamicHeight={false}
            >
              {data?.map((obj, index) => (
                <Card key={index} obj={obj} />
              ))}
            </Carousel>
          )}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
