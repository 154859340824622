import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NothingFound = () => {
    return (
      <div className="nothing-found pt-50">
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-xl-8 col-sm-12">
              <div className="thanks-modal-content mb-50">
                <div className="row" style={{ justifyContent: "center" }}>
                  <div className="col-xl-12 col-sm-12">
                    <img
                      src={require("../assets/404-illustration.png")}
                      width="500"
                      className="mobile-responsive-nothing"
                      alt="Order Placed"
                    />
                    <h3>There's nothing here</h3>
                    <p>We don't seem to find the page you are looking for.</p>
                    <Link to='/'>
                      <Button className="return-to-home">
                        Return to Homepage
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default NothingFound;