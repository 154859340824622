const fabricFilter = [
  {
    id: 1,
    fabricType: "Vinyl Fabric",
  },
  {
    id: 2,
    fabricType: "Lame Fabric",
  },
  {
    id: 3,
    fabricType: "Chiffon Fabric",
  },
  {
    id: 4,
    fabricType: "Velvet Sequins",
  },
  {
    id: 5,
    fabricType: "Mesh Fabric",
  },
  {
    id: 6,
    fabricType: "Rhinestone Fabric",
  },
  {
    id: 7,
    fabricType: "Lurex Fabric",
  },
];

export default fabricFilter;