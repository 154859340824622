const content = [
  {
    id: 1,
    content: "Polyester",
  },
  {
    id: 2,
    content: "Spandex",
  },
  {
    id: 3,
    content: "PU",
  },
  {
    id: 4,
    content: "Nylon",
  },
];

export default content;
