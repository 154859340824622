/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import {FiTrash2, FiShoppingBag} from "react-icons/fi"
import {useSnackbar} from "notistack"

/* COMPONENT IMPORTS */
import {cartList, deleteCart} from "../service/api"
import {useState} from "react"
import {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Link, useNavigate} from "react-router-dom"
/*REDUX PRODUCT ACTION*/
import productActions from "../redux/products/actions"
import {IMG_URL} from "../config"
import {Button} from "react-bootstrap"
import {useUpdateCart} from "../hooks/useUpdateCart"
import {IconButton} from "@mui/material"
function Cart() {
  const {enqueueSnackbar} = useSnackbar()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [total, setTotal] = useState(0)
  const {loggedIn} = useSelector((state) => state.user)
  const {cart} = useSelector((state) => state.products)
  const {setUpdateCart} = useUpdateCart()
  console.log("cart: ", cart)
  const [apiCall, setApiCall] = useState(false)

  useEffect(() => {
    setUpdateCart(true)
    let totalPrice = 0
    cart?.map((obj) => {
      return (totalPrice +=
        (obj?.product?.specialAmount
          ? obj?.product?.specialAmount
          : obj?.amount) * obj?.quantity)
    })
    console.log("totalPrice: ", totalPrice)
    console.log("cart: ", cart)
    setTotal(totalPrice)
  }, [cart])
  const [trigger, setTrigger] = useState(false)
  const getCartData = () => {
    let params = {
      page: 1,
      sizePerPage: 100,
    }
    cartList(params).then((res) => {
      // console.log("%c Line:48 🍢 res", "color:#fca650", res)
      if (res?.status) {
        dispatch(productActions.setCartData(res?.data?.docs))
      } else {
      }
    })
  }

  const handleProductClick = (data, color) => {
    console.log("data---->", data, color)
    dispatch(
      productActions.setSelectedProductId({
        selectedProductId: data?._id,
        selectedProductColorId: color?._id ? color?._id : color,
      }),
    )
    let wishlistActive = false
    if (data?.WishlistProduct?._id) {
      wishlistActive = true
    }
    dispatch(
      productActions.setWishlistData({
        wishlistActive: wishlistActive,
        productId: data?._id,
        colorId: data?.productImage.color,
      }),
    )
    navigate("/product")
  }

  const deleteCartList = (data) => {
    setApiCall(true)
    console.log("id", data)
    if (loggedIn) {
      deleteCart(data?._id).then((res) => {
        console.log(res)
        enqueueSnackbar(res?.message, {variant: "success"})
        setApiCall(false)

        if (res?.status) getCartData()
      })
    } else {
      let index = cart.indexOf(data)
      let data1 = cart
      dispatch(
        productActions.setCartData(data1.filter((obj, i) => index !== i)),
      )
      setApiCall(false)
    }
  }
  return (
    <div className="cart">
      <section className="mt-50 mb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="table-responsive">
                <table className="shopping-summary">
                  <thead>
                    <tr className="main-heading">
                      <th>Image</th>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Subtotal</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart && cart?.length > 0 ? (
                      cart?.map((product) => {
                        console.log(
                          "product: ",
                          product,
                          product.product?.productImage?.filter(
                            (data) =>
                              (data?.color?._id
                                ? data?.color?._id
                                : data?.color) === product?.color?._id,
                          ),
                        )
                        return (
                          <>
                            <tr>
                              <td className="image product-thumbnail">
                                <img
                                  src={
                                    IMG_URL + product.product?.productImage[0]
                                  }
                                  alt={product?.product?.name}
                                />
                              </td>
                              <td className="product-des">
                                <h5 className="product-name">
                                  <div
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleProductClick(
                                        product?.product,
                                        product?.color,
                                      )
                                    }>
                                    {product?.product?.name}
                                  </div>
                                </h5>
                                {/* <p>Color: {product?.color?.name}</p> */}
                              </td>
                              <td className="price">
                                <span>
                                  ₹{" "}
                                  {product?.product?.specialAmount
                                    ? product?.product?.specialAmount > 0
                                      ? product?.product?.specialAmount.toFixed(
                                          2,
                                        )
                                      : product?.product?.amount.toFixed(2)
                                    : product?.product?.amount.toFixed(2)}
                                </span>
                              </td>
                              <td className="price">
                                <span>{product?.quantity}</span>
                              </td>
                              <td className="price">
                                <span>
                                  ₹{" "}
                                  {(
                                    (product?.product?.specialAmount
                                      ? product?.product?.specialAmount > 0
                                        ? product?.product?.specialAmount
                                        : product?.product?.amount
                                      : product?.product?.amount) *
                                    product?.quantity
                                  ).toFixed(2)}
                                </span>
                              </td>
                              <td className="price delete-icon">
                                <IconButton
                                  disabled={apiCall}
                                  onClick={() => {
                                    deleteCartList(product)
                                  }}>
                                  <FiTrash2 />
                                </IconButton>
                              </td>
                            </tr>
                          </>
                        )
                      })
                    ) : (
                      <div className="cart-empty">
                        <h5>Your cart is empty.</h5>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="row mb-3 mt-3">
                <div className="col-lg-7 col-sm-12">
                  <div className="continue-shopping-button">
                    <Link to="/products" className="continue-btn">
                      <FiShoppingBag style={{marginRight: "10px"}} />
                      Continue Shopping
                    </Link>
                  </div>
                </div>
                <div className="col-lg-5 col-sm-12">
                  <div className="cart-total-box">
                    <div className="heading mb-3">Cart Total</div>
                    <div
                      className="table-responsive"
                      style={{
                        borderBottom: "0px solid #FFF",
                      }}>
                      <table>
                        <tbody>
                          <tr>
                            <td className="cart_total_label">Cart Subtotal</td>
                            <td className="cart_total_amount">
                              ₹ {total.toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td className="cart_total_label">Total</td>
                            <td className="cart_total_amount">
                              ₹ {total.toFixed(2)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <Button
                      onClick={() =>
                        loggedIn
                          ? navigate("/checkout")
                          : navigate("/checkout-options")
                      }
                      className="continue-btn"
                      disabled={cart?.length === 0}>
                      <FiShoppingBag style={{marginRight: "10px"}} />
                      Proceed To Checkout
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Cart
